/* eslint-disable */
<template>
  <div id="app">
    
    <keep-alive>
      <Sidebar id="sideBarId" ref="sideBarRef" v-touch:swipe.left="swipeHandler">
      </Sidebar>
    </keep-alive>
    
    <!--
    <NavigationBar v-if="loggedIn">
      <template v-slot:left>
        // menu, profile, macthdayselect, text (center, 2-zeilen, back)
        <button v-if="" v-on:click="openMenu">Menu</button>
        <button v-else-if="" v-on:click="openMenu">Menu</button>
      </template>
      <template v-slot:center>
        Tippabgabe<br>{{ matchday }}. Spieltag ({{ matchdayCount }})
      </template>
      <template v-slot:right>
        <button v-on:click="openMatchdaySelect">Select</button>
      </template>
    </NavigationBar>
    -->
    
    <router-view v-bind:class="{ pageoffset: true }" class="page" />

  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import NavigationBar from './components/NavigationBar'
import { menumutations } from '@/vuex/store.js'
import { TOAST_DURATION } from './main'
import Sidebar from './components/Menu/Sidebar'
import { bootstrap } from 'vue-gtag'
import Numeral from 'numeral'
import moment from 'moment'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Vue from 'vue'

export default {
  components: { NavigationBar, Sidebar },
  data() {
    return {
      showValidationHint: true,
      interval: null
    }
  },
  metaInfo () {
    return {
      title: 'Dein Tippspiel',
      titleTemplate: '%s | TEAMTIP',
      meta: [
        { property: 'og:title', content: 'TEAMTIP' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://teamtip.net/' },
        { property: 'og:image', content: 'https://teamtip.net/images/Teamtip_Logo_300x300.png' },
        { property: 'og:image:url', content: 'https://teamtip.net/images/Teamtip_Logo_300x300.png' },
        { property: 'og:image:width', content: '300' },
        { property: 'og:image:height', content: '300' },
        { property: 'og:site_name', content: 'TEAMTIP' },
        {
          property: 'og:description',
          content: 'TEAMTIP - dein kostenloses Tippspiel zur EM 2024, WM, Bundesliga, 2. Bundesliga, Champions League, Premier League, Primera División, La Liga, SüperLig, Serie A und Ligue 1 für Freunde, Familie und Kollegen mit unbegrenzter Teilnehmerzahl.'
        },
        //{ property: 'fb:app_id', content: '151742964909413' },        
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'kostenloses Tippspiel, Fussball, Online, Bundesliga, 2. Bundesliga, Champions League, Premier League, Primera División, La Liga, SüperLig, Serie A, Ligue 1, EURO 2024, WM, EM, Spiel, Kick, Tipp, Tippen, Umsonst, Tippkönig, Spieltagssieger, Halbzeit, Abpfiff, Live-Ergebnisse, Privat, unbegrentze Teilnehmerzahl, Online, Web, Tip, Social, Network, Mobile, App'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'TEAMTIP - dein kostenloses Tippspiel zur EM 2024, WM, Bundesliga, 2. Bundesliga, Champions League, Premier League, Primera División, La Liga, SüperLig, Serie A und Ligue 1 für Freunde, Familie und Kollegen mit unbegrenzter Teilnehmerzahl.'
        },
      ]
    }
  },
  computed: {
    ...authComputed
  },
  beforeCreate: function() {
    document.title = this.$i18n.t('app_name')
    this.$log.debug('app beforeCreate ' + this.$route.query.code + ' --- ' + this.$route.query.email + ' --- ' + document.domain)
    const api = 'https://teamtip.net'
    if(api !== undefined) {
      if(api.startsWith('http')) {
        window.localStorage.setItem('spmau', api)
      } else {
        window.localStorage.setItem('spmau', 'https://' + api)
      }
    } else {
      const apiUrl = window.localStorage.getItem('spmau')
      if(apiUrl === undefined || apiUrl === null || apiUrl === 'undefined') {
        const domain = document.domain
        if (domain !== undefined && (domain.includes('teamtip') || domain.includes('tippspiel') || domain.includes('deinsportplatz.de'))) {
          window.localStorage.setItem('spmau', 'https://' + domain)
        } else {
          window.localStorage.setItem('spmau', 'https://teamtip.net')
        }
      }
    }
    var id = this.$route.query.id
    if(id === undefined) {
      id = this.$route.query.betgameid
      if(id === undefined) {
        id = window.localStorage.getItem('spmbgid')
        if(id === undefined) {
          id = undefined
        }
      }
    }
    window.localStorage.setItem('spmbgid', id)
    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
      window.localStorage.setItem('betgameId', id)
      try {
        if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getTrackingAuthorizationStatus')
        }
      } catch (e) {
      }
    }
    const src = this.$route.query.src
    if(src !== undefined) {
      window.localStorage.setItem('userSource', src)
    }
    if(this.$route.query.utm_source) {
      window.localStorage.setItem('userSource', this.$route.query.utm_source)
    }
  },
  created () {
    this.$log.debug('app created ' + this.$route.params.id + ' --- ' + this.$route.name + ' --- ' + this.$route.query.code + ' --- ' + this.$route.query.email)
    if(this.getBGValue('spmau')) {
      this.$store.dispatch('setApiUrl', this.getBGValue('spmau'))
    }
    if(false && this.isSPMUser()) {
      window.appResumeEvent = new Event('appResumeEvent')
      window.addEventListener('appResumeEvent', this.appResumeEventFunction, false)
      window.appPullToRefreshEvent = new Event('appPullToRefreshEvent')
      window.addEventListener('appPullToRefreshEvent', this.appPullToRefreshEventFunction, false)
    }
    if((this.$route.name === 'validateregistration' || this.$route.name === 'vr') && this.$route.query.code && this.$route.query.email) {
      this.showValidationHint = false
      this.$store
      .dispatch(
              'validateRegistration',
              {
                email: String(this.$route.query.email).toLowerCase(),
                token: this.$route.query.code
              }
      )
      .then(() => {
        this.$toast.open({ message: this.$i18n.t('register.registration_finished_good_luck'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        if(this.$store.getters.loggedIn) {
          this.$router.push({ name: 'dashboard' }).catch(err => {})
        } else {
          this.$router.push({ name: 'home', query: { email: this.$route.query.email } })
        }
      }).catch(function (error) {
      })
    }
    if(this.$store.getters.loggedIn && this.$store.state.user.id) {
      if(this.$store.state.user.lang) {
        this.$store.state.language = this.$store.state.user.lang
        Numeral.locale(this.$store.state.user.lang)
        moment.locale(this.$store.state.user.lang)
        if(this.$i18n.locale !== this.$store.state.user.lang) {
          this.$log.debug('app created set i18n.locale ' + this.$store.state.user.lang)
          this.$i18n.locale = this.$store.state.user.lang
        } else {
          this.$log.debug('app created use i18n.locale ' + this.$store.state.user.lang)
        }
      }
      bootstrap().then(gtag => {
        this.$gtag.set({
          'user_id': '' + this.$store.state.user.id,
          'anonymize_ip': true,
          'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
          'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
          'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
          'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
        })
        if(this.$store.state.betgame && this.$store.state.user) {
          this.$gtag.set({
            user_properties: {
              permission_manage_prediction_round: '' + this.isAdmin(this.$store.state.betgame.id),
              prediction_mode: this.getBetgameType(this.$store.state.betgame),
              prediction_competiton: this.getCompetitionId(this.$store.state.betgame.id),
              notification_preferences: this.getNotificationPreferences(),
              user_age: (this.$store.state.user.isadult) ? '>=18' : '<18'
            },
          })
        }
        this.$gtag.config({ user_id: '' + this.$store.state.user.id })
        this.$gtag.pageview({
          page_title: this.$route.name,
          page_path: this.$route.path,
          page_location: window.location.href
        })
      })
      const ltr = new Date(this.getBGValue('spmltr'))
      this.setBGValue('spmltrc', new Date())
      if(ltr === undefined || ltr.getDate() !== new Date().getDate() || ltr.getFullYear() !== new Date().getFullYear() || ltr.getMonth() !== new Date().getMonth()) {
        if(this.$store.state.user && this.$store.state.user.status === 'active') {
          const data = {
            'id': '' + this.$store.state.user.id
          }
          this.$store.dispatch('refreshToken', data).then(response => {
          })
        }
      }
    }
    if ((!this.$store.state.user || !this.$store.state.user.lang) && this.$store.state.language) {
      Numeral.locale(this.$store.state.language)
      moment.locale(this.$store.state.language)
      if (this.$i18n.locale !== this.$store.state.language) {
        this.$log.debug('app created set i18n.locale ' + this.$store.state.language)
        this.$i18n.locale = this.$store.state.language
      } else {
        this.$log.debug('app created use i18n.locale ' + this.$i18n.locale)
      }
    }
    this.$log.debug("app created this.getBGValue('spmbgid'): " + this.getBGValue('spmbgid'))
    if(this.getBGValue('spmbgid') === 'null' || this.getBGValue('spmbgid') === undefined || this.getBGValue('spmbgid') === 'undefined') {
      this.clearBGValue('spmbgid')
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        this.clearBGValue('betgameId')
      }
    }
    if(this.$route.params && this.$route.params.id && this.$route.params.code && this.$route.name === 'join') {
      this.$store.dispatch('checkInvitation', [this.$route.params.id, this.$route.params.code]).then(() => {
        this.setBGValue('spmbgid', this.$route.params.id)
        if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.setBGValue('betgameId', this.$route.params.id)
        }
      }).catch(function (error) {
      })
    }
    if(this.$store.state.geoData === null) {
      this.$store.dispatch('getGeoData', []).then(response => {
        }, error => {
        })
    }
    if(this.$route.params && this.$route.params.id && this.$route.name === 'betgame') {
      const id = this.$route.params.id
      this.$log.debug("app created 1 bg.id: " + id + ' --- ' + this.$store.getters.loggedIn + ' --- ' + this.$store.getters.isUserConnectedToBetgame(id))
      if(parseInt(id) > 0) {
        if(this.$store.getters.loggedIn && this.$store.getters.isUserConnectedToBetgame(id)) {
          this.setBGValue('spmbgid', id)
        }
      }
    }
    if(this.getBGValue('spmbgid')) {
      const id = this.getBGValue('spmbgid')
      this.$log.debug("app created bg.id: " + id)
      if(parseInt(id) > 0) {
        this.$store.dispatch('setBetgameId', id)
        //this.$store.dispatch('fetchBetgameUsers', id)
        if (!this.$store.state.betgame || parseInt(this.$store.state.betgame.id) !== parseInt(id)) {
          this.$store.dispatch('fetchBetgame', id).then(() => {
            this.styleDocument()
          })
        } else {
          if (this.$store.state.betgame) {
            this.styleDocument()
          }
        }
        if (!this.$store.state.currentSeason) {
          this.$store.dispatch('fetchCurrentSeason', id)
        }
        if (this.$store.state.token && this.$store.state.token.length > 0 &&
            (!this.$store.state.leagueId || !this.$store.state.teams || this.$store.state.teams.length === 0)) {
          this.$store.dispatch('fetchBetgameLeague', id).then(() => {
            this.$store.dispatch('fetchTeams', this.$store.state.leagueId).then(() => {

            })
          })
        }
      }
    }
    if(this.getBGValue('userSource')) {
      this.$store.dispatch('setUserSource', this.getBGValue('userSource'))
    }
    this.$log.debug('app created before token')
    if(this.$store.state.token) {
      const r = window.localStorage.getItem('spmlr')
      this.$log.debug('app created before token lastr ' + r)
      if(r !== undefined && r !== null && r.length >= 0 && r !== 'undefined') {
        if(this.$route.query !== null && this.$route.query !== undefined) {
          this.$router.push({ name: r, query: this.$route.query }).catch(err => {
          })
        } else {
          this.$router.push({ name: r }).catch(err => {
          })
        }
      } else {
        this.$router.push({ name: 'dashboard' }).catch(err => {})
      }
    }
  },
  mounted: function () {
    this.$log.debug('app mounted '  + this.$route.query.id + ' --- ' + this.$route.query.code + ' --- ' + this.$route.query.email)

    /*
    const data = {
      '_id': '' + 103,
      'first_name': 'bob',
      'email': 'bob103@spm.spm',
      'password': '1234567890'
    }
    this.$store.dispatch('chatCreateUser', data).then(response => {
      this.$log.debug('chatCreateUser response: ' + JSON.stringify(response))
      this.$store.dispatch('chatLoginUser', {'user': data._id, 'password': data.password}).then(response => {
        this.$log.debug('chatLoginUser response: ' + JSON.stringify(response))
        this.$store.dispatch('chatCreateConversation', {
          'title': 'Test Conversation',
          'participant_ids': ['103']
        }).then(response => {
          this.$log.debug('chatCreateConversation response: ' + JSON.stringify(response))
        })
        .catch(function (error) {
          this.$log.debug('chatCreateConversation error: ' + JSON.stringify(error))
        })
      })
      .catch(function (error) {
        this.$log.debug('chatLoginUser error: ' + JSON.stringify(error))
      })
    })
    .catch(function (error) {
      this.$log.debug('chatCreateUser error: ' + JSON.stringify(error))
    })
    */
    
    const sentLastActive = this.getBGValue('spmsla')
    const sentLastActiveSeconds = (new Date() - new Date(sentLastActive)) / 1000
    if(sentLastActiveSeconds > (60 * 60 * 24) && this.$store.state.token && this.$store.state.user) {
      this.$store.dispatch('patchUser', { last_active: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') }).then(() => {
        this.setBGValue('spmsla', new Date())
      })
    }
    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
      window.bgIsLoggedIn = this.bgIsLoggedIn
      window.bgRouterPush = this.bgRouterPush
      const index = navigator.userAgent.lastIndexOf('TEAMTIP/')
      if(index > -1 && this.$store.state.token && this.$store.state.user) {
        const sentAppLastActive = this.getBGValue('spmsala')
        const sentAppLastActiveSeconds = (new Date() - new Date(sentAppLastActive)) / 1000
        const version = navigator.userAgent.substr(index + 8)
        const pt = this.getPushToken()
        if(version && version.trim() !== '' && (this.$store.state.appVersion === '' || this.$store.state.appVersion !== version) || (sentAppLastActiveSeconds > (60 * 60 * 24))) {
          if(pt !== '' && pt !== 'undefined') {
            const data = {
              push_token: pt,
              fk_user: this.$store.state.user.id,
              app_version: version,
              last_active: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
            }
            this.$store.dispatch('patchUserApp', data).then(response => {
              this.setBGValue('spmsala', new Date())
            })
          }
        }
      }
    }
    if(this.showValidationHint && this.$store.state.user && this.$store.state.user.status !== undefined && this.$store.state.user.status !== 'active') {
      this.$toast.open({ message: this.$i18n.t('register.please_finish_registration'),
        position: 'top',
        duration: TOAST_DURATION,
        type: 'error'
      })
    }

    if(this.$store.state.betgame && this.$store.state.user) {
      const uid = '' + this.$store.state.user.id
      const uem = '' + this.$store.state.user.email.charAt(0) + this.reverse(uid)
      const temp = this.reverse(String(this.$store.state.user.registration_date)).replaceAll('T', this.reverse(uem))
      .replaceAll(':', '').replaceAll('-', uem).concat(uid)
      const addParticipant = this.$store.state.betgame.chat && this.$store.state.betgame.chat !== '' && 
          !this.$store.state.chats_participant.includes(this.$store.state.betgame.chat)
      if(!this.$store.state.chat_token || this.$store.state.chat_token === '') {
        if(this.$store.state.user.chatuser && this.$store.state.user.chatuser !== '') {
          if(this.$store.state.user.chatuser.includes('-')) {
            const t = this.$store.state.user.chatuser.split('-')
            if(t.length === 2) {
              this.$store.dispatch('chatLoginUser', {'user': t[0], 'password': t[1]}).then(response => {
                this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
                if(addParticipant && this.$store.state.betgame.chat) {
                  this.$store.dispatch('chatAddParticipant', { user: uid, chat: this.$store.state.betgame.chat }).then(response => {
                    this.$store.dispatch('fetchChatItems', true).then(() => {
                    })
                  })
                } else if(this.$store.state.betgame.chat) {
                  this.$store.dispatch('fetchChatItems', true).then(() => {
                  })
                }
              })
            }
          } else {
            this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
              this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
              this.$store.dispatch('patchUser', {
                chatuser: uid + '-' + temp
              })
              if(addParticipant && this.$store.state.betgame.chat) {
                this.$store.dispatch('chatAddParticipant', { user: uid, chat: this.$store.state.betgame.chat }).then(response => {
                  this.$store.dispatch('fetchChatItems', true).then(() => {
                  })
                })
              } else if(this.$store.state.betgame.chat) {
                this.$store.dispatch('fetchChatItems', true).then(() => {
                })
              }
            })
          }
        } else {
          const data = {
            '_id': uid,
            'first_name': this.$store.state.user.nickname,
            'email': this.$store.state.user.email,
            'password': temp
          }
          this.$store.dispatch('chatCreateUser', data).then(response => {
            this.$log.debug('app mounted chatCreateUser response: ' + JSON.stringify(response))
            this.$store.dispatch('patchUser', {
              chatuser: uid + '-' + temp
            })
            this.$store.dispatch('chatLoginUser', {'user': data._id, 'password': data.password}).then(response => {
              this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
              if(this.$store.state.betgame.chat) {
                this.$store.dispatch('chatAddParticipant', { user: uid, chat: this.$store.state.betgame.chat }).then(response => {
                  this.$store.dispatch('fetchChatItems', true).then(() => {
                  })
                })
              }
            })
            .catch(function (error) {
              this.$log.debug('app mounted chatLoginUser error: ' + JSON.stringify(error))
            })
          })
          .catch(function (error) {
            this.$log.debug('app mounted chatCreateUser error: ' + JSON.stringify(error))
          })
        }
      } else {
        const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
        const a = payload.exp * 1000
        const b = moment()
        if(a < b) {
          this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
            this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
            if(addParticipant && this.$store.state.betgame.chat) {
              this.$store.dispatch('chatAddParticipant', { user: uid, chat: this.$store.state.betgame.chat }).then(response => {
                this.$store.dispatch('fetchChatItems', true).then(() => {
                })
              })
            } else if(this.$store.state.betgame.chat) {
              this.$store.dispatch('fetchChatItems', true).then(() => {
              })
            }
          })
        }
        if(!this.$store.state.user.chatuser.includes('-')) {
          this.$store.dispatch('patchUser', {
            chatuser: uid + '-' + temp
          })
        }
      }

      const createChat = this.isAdmin(this.$store.state.betgame.id) && (!this.$store.state.betgame.chat || this.$store.state.betgame.chat === '')
      if(createChat) {
        if(this.$store.state.chat_token && this.$store.state.chat_token !== '') {
          const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
          const a = payload.exp * 1000
          const b = moment()
          if(a > b) {
            this.$store.dispatch('chatCreateConversation', {
              'title': this.$store.state.betgame.name,
              'participant_ids': [uid]
            }).then(response => {
              this.$log.debug('app mounted chatCreateConversation response: ' + JSON.stringify(response))
            })
            .catch(function (error) {
              this.$log.debug('app mounted chatCreateConversation error: ' + JSON.stringify(error))
            })
          }
        }
      } else {
        if(this.$store.state.betgame.chat && this.$store.state.betgame.chat !== '' && this.$store.state.chat_token && this.$store.state.chat_token !== '') {
          if (!this.$store.state.chats_participant.includes(this.$store.state.betgame.chat)) {
            this.$store.dispatch('chatAddParticipant', { user: this.$store.state.user.id, chat: this.$store.state.betgame.chat }).then(response => {
              this.$store.dispatch('fetchChatItems', true).then(() => {
              })
            })
          } else {
            this.$store.dispatch('fetchChatItems', true).then(() => {
            })
          }
          if (this.interval == null) {
            this.interval = setInterval(() => {
              this.$store.dispatch('fetchChatItems', true).then(() => {
                //Vue.$log.debug('App mounted fetchChatItems')
              })
            }, 10000)
          }
        }
      }
    }
  },
  beforeDestroy () {
    if(this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    closeSidebarPanel: menumutations.toggleNav,
    swipeHandler (direction) {
      this.closeSidebarPanel()
    },
    appResumeEventFunction() {
      this.$toast.open({ message: 'App.vue appResumeEventFunction',
        position: 'top',
        duration: TOAST_DURATION,
        type: 'success'
      })
      return 'appResumeEvent'
    },
    appPullToRefreshEventFunction() {
      this.$toast.open({ message: 'App.vue appPullToRefreshEventFunction',
        position: 'top',
        duration: TOAST_DURATION,
        type: 'success'
      })
      const r = this.$router.currentRoute.path
      return 'appPullToRefreshEvent'
    },
    getPushToken() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          return window.TeamtipAppInterface.getPushToken()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getPushToken')
          return window.localStorage.getItem('pushToken')
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    bgIsLoggedIn(data) {
      return this.$store.getters.loggedIn
    },
    bgRouterPush(path) {
      this.$router.push( path ).catch(err => {})
    },
    styleDocument() {
      if(this.$store.state.betgame && this.$store.state.betgame.primary_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.primary_color)
        document.documentElement.style.setProperty('--color-primary', this.$store.state.betgame.primary_color)
        document.documentElement.style.setProperty('--color-primary-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-primary', '#0C3EC1')
        document.documentElement.style.setProperty('--color-primary-rgb', '12,62,193')
      }
      if(this.$store.state.betgame && this.$store.state.betgame.button_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.button_color)
        document.documentElement.style.setProperty('--color-button', this.$store.state.betgame.button_color)
        document.documentElement.style.setProperty('--color-button-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-button', '#0C3EC1')
        document.documentElement.style.setProperty('--color-button-rgb', '12,62,193')
      }
      if(this.$store.state.betgame && this.$store.state.betgame.link_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.link_color)
        document.documentElement.style.setProperty('--color-link', this.$store.state.betgame.link_color)
        document.documentElement.style.setProperty('--color-link-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-link', '#0C3EC1')
        document.documentElement.style.setProperty('--color-link-rgb', '12,62,193')
      }      
    }
  },
  watch:{
    $route (to, from){
      // this.$log.debug(from.path + " --- " + to.patch + " --- " + this.$router.currentRoute.path)
    }
  }
}
</script>

<style lang="scss">
  
  @import './assets/styles/global.scss';

  #app {
    height: 100%;
  }
  
  .pageoffset {
    padding-top: 64px !important;
  }
  
</style>
