import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import VuexPersistence from 'vuex-persist'
import APIService from '@/services/APIService'
import { isNumeric } from '@/vuex/helpers'
import {
    API_TIMEOUT, BATTLE_COMPETITION, BETMODE_RESULT,
    DASHBOARD_RANKINGS_SIZE, DEFAULT_COMPETITION,
    MATCH_DATE_FORMAT,
    PADDING_BOTTOM_DEFAULT,
    PADDING_BOTTOM_DSP,
    PADDING_BOTTOM_FUNKE,
    TOP_TEN, TYPE_BATTLE, TYPE_BATTLE_ID, TYPE_BETGAME, TYPE_BETGAME_ID, TYPE_TEAMS, TYPE_TEAMS_ID
} from '@/main'
import moment from 'moment'
import { isToday } from './helpers'
import i18n from '@/i18n'
/*
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
*/

Vue.use(Vuex)

/*
const cookieName = 'spmcn';
const storageKey = 'spmsk';
// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuidv4();
// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, process.env.NODE_ENV === 'production' ? { sameSite: 'lax', secure: true, expires: 360 } : { expires: 360 });
const vuexLocal = new VuexPersistence({
    storage: {
        getItem: () => {
            // Get the store from local storage.
            const store = window.localStorage.getItem(storageKey);
            
            if (store) {
                try {
                    // Decrypt the store retrieved from local storage
                    // using our encryption token stored in cookies.
                    const bytes = Crypto.AES.decrypt(store, encryptionToken);
                    
                    return JSON.parse(bytes.toString(Crypto.enc.Utf8));
                } catch (e) {
                    // The store will be reset if decryption fails.
                    window.localStorage.removeItem(storageKey);
                }
            }
            
            return null;
        },
        setItem: (key, value) => {
            // Encrypt the store using our encryption token stored in cookies.
            const store = Crypto.AES.encrypt(value, encryptionToken).toString();
            
            // Save the encrypted store in local storage.
            return window.localStorage.setItem(storageKey, store);
        },
        removeItem: () => window.localStorage.removeItem(storageKey),
    },
})
*/

const vuexLocal = new VuexPersistence({
    key: 'spmvx',
    storage: window.localStorage
})

function setBGValue(key, value) {
    window.localStorage.setItem(key, value)
}

function getBGValue(key) {
    return window.localStorage.getItem(key)
}

export default new Vuex.Store({
    state: {
        paddingBottom: PADDING_BOTTOM_DEFAULT,
        colorGreyLight: '#5F5D61',
        colorPrimary: '#0C3EC1',
        tournamentRounds: [],
        tournamentGroups: [],
        tournamentMatches: [],
        tournamentGroupTables: [],
        matches: [],
        bets: [],
        allbets: [],
        matchdayCount: 0,
        currentMatchdayBet: 1,
        currentMatchdayRankingByMatchday: 1,
        matchdayRankingSorting: 1,
        positions: [],
        positions_at_m: [],
        positions_at_q: [],
        liveRankings: [],
        liveRankingsByMatchday: [],
        teams: [],
        betgame: null,
        matchDayWins: [],
        lastBetSaveSuccess: null,
        lastAnswerSaveSuccess: null,
        userRanking: null,
        userLiveRanking: null,
        userMatchdayRankings: [],
        userNeighbourRankings: null,
        matchdayRankings: [],
        betgameId: null,
        userSource: null,
        apiUrl: null,
        userBetgames: [],
        leagueId: null,
        currentSeason: { id: 15, name: '2024/25', is_current: true },
        runningMatches: [],
        todaysMatches: [],
        matchBets: [],
        user: null,
        token: null,
        betgames_owner: null,
        betgames_author: null,
        betgames_participant: null,
        battles: null,
        missing_bets: null,
        questions: null,
        solutions: null,
        answers: null,
        updateAnswerCount: true,
        sidebarComponentKey: 0,
        userCount: -1,
        betCount: -1,
        incomingBetgameTransfers: [],
        updateUserBetgames: true,
        last_token_refresh_check: null,
        last_token_refresh: null,
        lastFetchSchedule: null,
        lastRoute: null,
        betgameUsers: [],
        language: (navigator.language.split('-')[0] === 'de' ? 'de' : 'en') || 'en',
        geoData: null,
        userBetgamePoints: [],
        questionRankings: [],
        pointsCorrectionsCount: 0,
        appVersion: '',
        betgameCompetitions: [],
        betgameInfos: [],
        questionAnswers: [],
        betgameRankings: [],
        liveticker: [],
        liveresults: [],
        maxStartedMatchday: 1,
        createBetgame: {
            betgameName: '',
            betgameDescription: '',
            betgameNameTemplate: '',
            betgameType: TYPE_BETGAME,
            userName: '',
            email: '',
            password: '',
            betmode: '' + BETMODE_RESULT,
            addUsers: false,
            addUsersBetgame: 0,
            agreedTOS: false,
            isUnder18: false,
            selectedMatches: {},
            selectedTeams: [],
            competition: '' + DEFAULT_COMPETITION
        },
        unsubscribed: null,
        battleBetSelectedTab: null,
        competitions: [],
        betgameTeams: [],
        specialQuestionTeams: [],
        betgameTeamsCompetitions: [],
        reviewPopupShown: 0,
        ratings: [],
        chat_token: '',
        chat_items: [],
        unreadCount: 0,
        chats_participant: [],
        trendBoxSize: 5
    },
    mutations: {
        SET_TRENDBOX_SIZE (state, data) {
            state.trendBoxSize = data
        },
        SET_CHATS_PARTICIPANT (state, data) {
            state.chats_participant = data
        },
        ADD_CHATS_PARTICIPANT (state, data) {
            state.chats_participant.push(data)
        },
        SET_UNREAD_COUNT (state, count) {
            state.unreadCount = count
        },
        ADD_CHAT_ITEMS (state, temp) {
            var chatItems = []
            for (let i = 0; i < temp.length; i++) {
                var text = temp[i].text
                if (temp[i].status === -1) {
                    text = this.state.user.id === parseInt(temp[i].user_id) ? i18n.t('feed.message_deleted_by_you') : i18n.t('feed.message_deleted')
                }
                var in_response_to_message = {}
                if (temp[i].in_response_to) {
                    const t = temp.filter(function (item) {
                        return item._id === temp[i].in_response_to
                    })
                    if (t.length > 0) {
                        in_response_to_message = t[0]
                        const u = this.getters.getUserById(parseInt(t[0].user_id))
                        in_response_to_message.username = u ? u.nickname : ''
                    } else {
                        const t = state.chat_items.filter(function (item) {
                            return item._id === temp[i].in_response_to
                        })
                        if (t.length > 0) {
                            in_response_to_message = t[0]
                            in_response_to_message.username = this.getters.getUserById(parseInt(t[0].userid)) ?
                              this.getters.getUserById(parseInt(t[0].userid)).nickname : ''
                        }
                    }
                }
                var isbot = temp[i].user_id === '66d6cc37c8bd3964ba43cfcf'
                chatItems.unshift({
                    id: chatItems.length + 1,
                    _id: temp[i]._id,
                    sender: this.state.user.id === parseInt(temp[i].user_id),
                    reacted: false,
                    reactions: temp[i].reactions ? temp[i].reactions : [],
                    username: isbot ? 'TEAMTIP-BOT' : (this.getters.getUserById(parseInt(temp[i].user_id)) ? this.getters.getUserById(parseInt(temp[i].user_id)).nickname : ''),
                    userid: parseInt(temp[i].user_id),
                    messageid: temp[i]._id,
                    in_response_to: temp[i].in_response_to,
                    in_response_to_message: in_response_to_message,
                    time: new Date(Math.round(temp[i].timestamp * 1000)),
                    timestamp: Math.round(temp[i].timestamp * 1000),
                    modified_at: temp[i].modified_at ? Math.round(temp[i].modified_at * 1000) : 0,
                    text: text,
                    responses: [],
                    status: temp[i].status,
                    isbot: isbot
                })
            }
            var r1 = []
            for(let j = 0; j < state.chat_items.length; j++) {
                var found = false
                for(let i = 0; i < chatItems.length; i++) {
                    if(chatItems[i]._id === state.chat_items[j]._id) {
                        found = true
                        break
                    }
                }
                if(!found) {
                    r1.push(state.chat_items[j])
                }
            }
            var temp = r1.concat(chatItems)
            temp = temp.sort(function(a, b) {
                return a.timestamp - b.timestamp
            })
            var lastuser = temp.length > 0 ? temp[0].userid : -1
            var displayDate = ''
            for(let i = 0; i < temp.length; i++) {
                temp[i].id = temp.length - i
                if(temp[i].timestamp) {
                    try {
                        const dd = new Date(temp[i].timestamp).toLocaleDateString()
                        if (i === 0 || dd !== displayDate) {
                            temp[i].displayDate = dd
                            displayDate = dd
                        }
                    } catch (e) {}
                }
                temp[i].showAvatar = true
                if(i === 0) {
                    temp[i].showUser = true
                    lastuser = temp[i].userid
                    if(temp.length > 1) {
                        const nextuser = temp[i + 1].userid
                        const n1 = temp[i + 1].timestamp
                        const n2 = temp[i].timestamp
                        const n = Math.round((n1 - n2) / 1000)
                        temp[i].showAvatar = n > 60 || nextuser !== lastuser
                    }
                } else {
                    const t1 = temp[i - 1].timestamp
                    const t2 = temp[i].timestamp
                    const t = Math.round((t2 - t1) / 1000)
                    temp[i].showUser = t > 60 || lastuser !== temp[i].userid
                    lastuser = temp[i].userid
                    if(i < temp.length - 1) {
                        const nextuser = temp[i + 1].userid
                        const n1 = temp[i + 1].timestamp
                        const n2 = temp[i].timestamp
                        const n = Math.round((n1 - n2) / 1000)
                        temp[i].showAvatar = n > 60 || nextuser !== lastuser
                    }
                }
            }
            state.chat_items = temp
        },
        SET_CHAT_ITEMS (state, temp) {
            var chatItems = []
            for (let i = 0; i < temp.length; i++) {
                var text = temp[i].text
                if (temp[i].status === -1) {
                    text = this.state.user.id === parseInt(temp[i].user_id) ? i18n.t('feed.message_deleted_by_you') : i18n.t('feed.message_deleted')
                }
                var in_response_to_message = {}
                if (temp[i].in_response_to) {
                    const t = temp.filter(function (item) {
                        return item._id === temp[i].in_response_to
                    })
                    if (t.length > 0) {
                        in_response_to_message = t[0]
                        const u = this.getters.getUserById(parseInt(t[0].user_id))
                        in_response_to_message.username = u ? u.nickname : ''
                    }
                }
                var isbot = temp[i].user_id === '66d6cc37c8bd3964ba43cfcf'
                const user = this.getters.getUserById(parseInt(temp[i].user_id))
                chatItems.unshift({
                    id: chatItems.length + 1,
                    _id: temp[i]._id,
                    sender: this.state.user.id === parseInt(temp[i].user_id),
                    reacted: false,
                    reactions: temp[i].reactions ? temp[i].reactions : [],
                    username: isbot ? 'TEAMTIP-BOT' : (user ? user.nickname : ''),
                    userid: parseInt(temp[i].user_id),
                    messageid: temp[i]._id,
                    in_response_to: temp[i].in_response_to,
                    in_response_to_message: in_response_to_message,
                    time: new Date(Math.round(temp[i].timestamp * 1000)),
                    timestamp: Math.round(temp[i].timestamp * 1000),
                    modified_at: temp[i].modified_at ? Math.round(temp[i].modified_at * 1000) : 0,
                    text: text,
                    responses: [],
                    status: temp[i].status,
                    isbot: isbot
                })
            }
            var lastuser = chatItems.length > 0 ? chatItems[0].userid : -1
            var displayDate = ''
            for(let i = 0; i < chatItems.length; i++) {
                chatItems[i].id = chatItems.length - i
                if(chatItems[i].timestamp) {
                    try {
                        const dd = new Date(chatItems[i].timestamp).toLocaleDateString()
                        if (i === 0 || dd !== displayDate) {
                            chatItems[i].displayDate = dd
                            displayDate = dd
                        }
                    } catch (e) {}
                }
                chatItems[i].showAvatar = true
                if(i === 0) {
                    chatItems[i].showUser = true
                    lastuser = chatItems[i].userid
                    if(chatItems.length > 1) {
                        const nextuser = chatItems[i + 1].userid
                        const n1 = chatItems[i + 1].timestamp
                        const n2 = chatItems[i].timestamp
                        const n = Math.round((n1 - n2) / 1000)
                        chatItems[i].showAvatar = n > 60 || nextuser !== lastuser
                    }
                } else {
                    const t1 = chatItems[i - 1].timestamp
                    const t2 = chatItems[i].timestamp
                    const t = Math.round((t2 - t1) / 1000)
                    chatItems[i].showUser = t > 60 || lastuser !== chatItems[i].userid
                    lastuser = chatItems[i].userid
                    if(i < chatItems.length - 1) {
                        const nextuser = chatItems[i + 1].userid
                        const n1 = chatItems[i + 1].timestamp
                        const n2 = chatItems[i].timestamp
                        const n = Math.round((n1 - n2) / 1000)
                        chatItems[i].showAvatar = n > 60 || nextuser !== lastuser
                    } 
                }
            }
            state.chat_items = chatItems
        },
        SET_CHAT_TOKEN (state, data) {
            state.chat_token = data
        },
        SET_SPECIAL_QUESTION_TEAMS (state, data) {
            state.specialQuestionTeams = data
        },
        ADD_RATING (state, data) {
            state.ratings.push(data)
        },
        SET_REVIEW_POPUP_SHOWN (state, data) {
            state.reviewPopupShown = data
        },
        SET_BETGAME_TEAMS_COMPETITIONS (state, data) {
            state.betgameTeamsCompetitions = data
        },
        SET_BETGAME_TEAMS (state, data) {
            state.betgameTeams = data
        },
        SET_COMPETITIONS (state, data) {
            state.competitions = data
        },
        SET_BATTLE_BET_SELECTED_TAB (state, data) {
            state.battleBetSelectedTab = data
        },
        SET_BETGAME_INFOS (state, data) {
            state.betgameInfos = data
        },
        SET_BATTLES (state, data) {
            state.battles = data
        },
        CLEAR_CREATE_BETGAME (state, unused) {
            state.createBetgame.betgameName = ''
            state.createBetgame.betgameDescription = ''
            state.createBetgame.betgameNameTemplate = ''
            state.createBetgame.betgameType = TYPE_BETGAME
            state.createBetgame.userName = ''
            state.createBetgame.email = ''
            state.createBetgame.password = ''
            state.createBetgame.betmode = '' + BETMODE_RESULT
            state.createBetgame.addUsers = false
            state.createBetgame.addUsersBetgame = 0
            state.createBetgame.agreedTOS = false
            state.createBetgame.isUnder18 = false
            state.createBetgame.selectedMatches = {}
            state.createBetgame.selectedTeams = {}
            state.createBetgame.competition = '' + DEFAULT_COMPETITION
        },
        SET_CREATE_BETGAME (state, data) {
            Vue.$log.debug('SET_CREATE_BETGAME ' + data.key + ' --- ' + JSON.stringify(data.value))
            if(data.key === 'betgameType') {
                if(data.value === TYPE_BATTLE) {
                    state.createBetgame['competition'] = '' + BATTLE_COMPETITION 
                } else if (data.value === TYPE_BETGAME) {
                    if(state.createBetgame['competition'] === '' + BATTLE_COMPETITION) {
                        state.createBetgame.competition = '' + DEFAULT_COMPETITION
                        state.createBetgame.selectedMatches = {}
                    }
                } else if(data.value === TYPE_TEAMS) {
                    state.createBetgame['competition'] = '' + BATTLE_COMPETITION
                }
            }
            state.createBetgame[data.key] = data.value
            Vue.$log.debug('SET_CREATE_BETGAME ' + JSON.stringify(state.createBetgame))
        },
        CLEAR_SELECTED_MATCHES (state, unused) {
            for (var key in state.createBetgame.selectedMatches) {
                if (state.createBetgame.selectedMatches.hasOwnProperty(key)) {
                    delete state.createBetgame.selectedMatches[key]
                }
            }
        },
        CLEAR_SELECTED_TEAMS (state, unused) {
            state.createBetgame.selectedTeams = []
        },
        SET_SELECTED_TEAMS (state, teams) {
            Vue.$log.debug('SET_SELECTED_TEAMS -' + JSON.stringify(teams))
            var temp = []
            for (var key in teams) {
                if (teams.hasOwnProperty(key) && teams[key]) {
                    temp.push(parseInt(key))
                }
            }
            Vue.$log.debug('SET_SELECTED_TEAMS - ' + JSON.stringify(temp))
            state.createBetgame.selectedTeams = temp
        },
        SET_SELECTED_MATCHES (state, data) {
            Vue.$log.debug('SET_SELECTED_MATCHES1 -' + JSON.stringify(state.createBetgame.selectedMatches))
            Vue.$log.debug('SET_SELECTED_MATCHES2 -' + data.competition + ' --- ' + JSON.stringify(data.matches))
            if(data.competition) {
                state.createBetgame.selectedMatches['' + data.competition] = data.matches
            }
            Vue.$log.debug('SET_SELECTED_MATCHES3 -' + JSON.stringify(state.createBetgame.selectedMatches))
        },
        SET_MAX_STARTED_MATCHDAY (state, matchday) {
            state.maxStartedMatchday = matchday
        },
        SET_LIVETICKER (state, data) {
            state.liveticker = data
        },
        SET_LIVE_RESULTS (state, data) {
            state.liveresults = data
        },
        SET_BETGAME_RANKINGS (state, data) {
            for (var i = 0; i < data.length; i++) {
                const r = data[i]
                if (i === 0) {
                    data[i]["position_int"] = 1
                } else {
                    const r1 = data[i]
                    const r2 = data[i - 1]
                    if (!state.betgame.sortmode || state.betgame.sortmode === 1) {
                        if (r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
                            data[i]["position_int"] = r2["position_int"]
                        } else {
                            data[i]["position_int"] = i + 1
                        }
                    } else if (state.betgame.sortmode === 2) {
                        if (r1.points_total === r2.points_total && r1.exact === r2.exact && r1.matchday_wins === r2.matchday_wins && r1.goaldiff === r2.goaldiff) {
                            data[i]["position_int"] = r2["position_int"]
                        } else {
                            data[i]["position_int"] = i + 1
                        }
                    } 
                }
            }
            state.betgameRankings = data
        },
        SET_QUESTION_ANSWERS (state, answers) {
            if(answers.length > 0) {
                const t = answers[0].fk_question
                Vue.set(state.questionAnswers, t,answers)
            } else {
                state.questionAnswers = []
            }
        },
        SET_BETGAME_COMPETITIONS (state, data) {
            state.betgameCompetitions = data
        },
        SET_APP_VERSION (state, version) {
            state.appVersion = version
        },
        SET_POINTS_CORRECTIONS_COUNT (state, count) {
            state.pointsCorrectionsCount = count
        },
        SET_USER_BETGAME_POINTS(state, data) {
            state.userBetgamePoints = data
        },
        SET_QUESTION_RANKINGS(state, data) {
            state.questionRankings = data
        },
        UPDATE_USER_BETGAME_POINTS (state, data) {
            const oldData = state.userBetgamePoints.filter(p => p.fk_user === data.fk_user && p.fk_betgame === data.fk_betgame)
            if(oldData != null && oldData.length === 1) {
                oldData[0].points = data.points
            }
            state.userBetgamePoints = state.userBetgamePoints.filter(function (item) {
                return true
            })
        },
        SET_USER_AVATAR(state, avatar) {
            state.user.avatar = avatar
        },
        SET_GEO_DATA(state, data) {
            state.geoData = data
        },
        SET_LANGUAGE(state, lang) {
            state.language = lang
            if(state.user) {
                state.user.lang = lang
            }
        },
        SET_BETGAME_ICON(state, icon) {
            state.betgame.icon = icon
        },
        SET_BETGAME_INVITATION_CODE(state, code) {
            state.betgame.invitation_code = code
        },
        SET_BETGAME_USERS(state, data) {
            state.betgameUsers = data
        },
        SET_LAST_ROUTE(state, data) {
            state.lastRoute = data
        },
        SET_LAST_FETCH_SCHEDULE(state, data) {
            state.lastFetchSchedule = data
        },
        SET_LAST_TOKEN_REFRESH_CHECK(state, data) {
            state.last_token_refresh_check = data
        },
        SET_LAST_TOKEN_REFRESH(state, data) {
            state.last_token_refresh = data
        },
        SET_TOURNAMENT_GROUP_TABLESS(state, data) {
            state.tournamentGroupTables = data
        },
        SET_TOURNAMENT_GROUPS(state, data) {
            state.tournamentGroups = data
        },
        SET_TOURNAMENT_MATCHES(state, data) {
            state.tournamentMatches = data
        },
        SET_TOURNAMENT_ROUNDS(state, data) {
            const t = data.sort(function(a, b) {
                return a.sorting - b.sorting
            })
            state.tournamentRounds = t
        },
        SET_UPDATE_ANSWER_COUNT(state, doUpdate) {
            state.updateAnswerCount = doUpdate
        },
        SET_UPDATE_USER_BETGAMES(state, doUpdate) {
            state.updateUserBetgames = doUpdate
        },
        SET_BETGAME_COMPETITION(state, data) {
            state.betgame.competition = data
        },
        SET_BETGAME_SEASON(state, data) {
            state.betgame.season = data
        },
        SET_MISSING_BETS(state, data) {
            state.missing_bets = data
        },
        SET_INCOMING_BETGAME_TRANSFERS(state, transfers) {
            state.incomingBetgameTransfers = transfers
        },
        SET_BETGAME_TRANSFER(state, transfer) {
          state.betgame.transfer = transfer
        },
        SET_USER_COUNT (state, count) {
            state.userCount = count
        },
        SET_BET_COUNT (state, count) {
            state.betCount = count
        },
        SET_SIDEBAR_COMPONENT_KEY (state, data) {
            state.sidebarComponentKey = data
        },
        SET_QUESTIONS (state, data) {
            for (var i = 0; i < data.length; i++) {
                var d = data[i]
                if(d.type === 'TEAM') {
                    if (d.options !== null && d.options !== undefined) {
                        try {
                            d.optionsJSON = JSON.parse(d.options.replace(/^\s+|\s+$/g, ''))
                            d.optionsJSON.sort(function(a,b){
                                var x = a.name < b.name? -1:1;
                                return x;
                            })
                        } catch (e) {
                            d.optionsJSON = []
                        }
                    } else {
                        d.optionsJSON = []
                    }
                }
                data[i] = d
            }
            state.questions = data
        },
        SET_ANSWERS (state, data) {
            state.answers = data
        },
        SET_SOLUTIONS (state, data) {
            state.solutions = data
        },
        SET_BETGAMES_OWNER (state, data) {
            state.betgames_owner = data.sort((a, b) => a.id - b.id)
        },
        ADD_BETGAMES_OWNER (state, data) {
            state.betgames_owner.push(data)
            state.betgames_owner = state.betgames_owner.sort((a, b) => a.id - b.id)
        },
        SET_BETGAMES_AUTHOR (state, data) {
            state.betgames_author = data
        },
        SET_BETGAMES_PARTICIPANT (state, data) {
            state.betgames_participant = data
        },
        ADD_BETGAMES_PARTICIPANT (state, data) {
            state.betgames_participant.push(data)
            state.betgames_participant = state.betgames_participant.sort((a, b) => a.id - b.id)
        },
        SET_MATCHDAY_RANKING_SORTING (state, sorting) {
            state.matchdayRankingSorting = sorting
        },
        SET_PADDING_BOTTOM (state, data) {
            state.paddingBottom = data
        },
        SET_MATCH_BETS (state, data) {
            state.matchBets = data
        },
        SET_TODAYS_MATCHES (state, season) {
            state.todaysMatches = season
        },
        SET_RUNNING_MATCHES (state, season) {
            state.runningMatches = season
        },
        SET_CURRENT_SEASON (state, season) {
            state.currentSeason = season
        },
        SET_LEAGUE_ID (state, league) {
            state.leagueId = league
        },
        SET_USER_BETGAMES (state, betgames) {
            state.userBetgames = betgames
        },
        SET_BETGAME_ID (state, id) {
            state.betgameId = id
        },
        SET_USER_SOURCE (state, src) {
            state.userSource = src
        },
        SET_API_URL (state, url) {
            state.apiUrl = url
        },
        SET_USER_NEIGHBOUR_RANKINGS (state, rankings) {
            state.userNeighbourRankings = rankings
        },
        SET_USER_RANKING (state, ranking) {
            state.userRanking = ranking[0]
        },
        SET_USER_LIVE_RANKING (state, ranking) {
            state.userLiveRanking = ranking[0]
        },
        SET_LIVE_RANKINGS_BY_MATCHDAY (state, params) {
            if(params.length === 2) {
                Vue.set(state.liveRankingsByMatchday, params[0], params[1])
                //state.liveRankings = rankings
            } else {
                state.liveRankingsByMatchday = []
            }
        },
        SET_LIVE_RANKINGS (state, rankings) {
            for (var i = 0; i < rankings.length; i++) {
                const r = rankings[i]
                if (i === 0) {
                    rankings[i]["position_int"] = 1
                } else {
                    const r1 = rankings[i]
                    const r2 = rankings[i - 1]
                    if (!state.betgame.sortmode || state.betgame.sortmode === 1) {
                        if (r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
                            rankings[i]["position_int"] = r2["position_int"]
                        } else {
                            rankings[i]["position_int"] = i + 1
                        }
                    } else if (state.betgame.sortmode === 2) {
                        if (r1.points_total === r2.points_total && r1.exact === r2.exact && r1.matchday_wins === r2.matchday_wins && r1.goaldiff === r2.goaldiff) {
                            rankings[i]["position_int"] = r2["position_int"]
                        } else {
                            rankings[i]["position_int"] = i + 1
                        }
                    }
                }
            }
            state.liveRankings = rankings
        },
        ADD_LIVE_RANKINGS (state, rankings) {
            if(state.liveRankings.length > 0) {
                const t = state.liveRankings.pop()
            }
            state.liveRankings = state.liveRankings.concat(rankings)
        },
        UPDATE_BET (state, betData) {
            const oldBet = state.bets.filter(bet => bet.fk_user === betData.fk_user && bet.fk_match === betData.fk_match && bet.fk_betgame === betData.fk_betgame)
            if(oldBet != null && oldBet.length === 1) {
                oldBet[0].goalshome = betData.goalshome
                oldBet[0].goalsguest = betData.goalsguest
            } else {
                state.bets.push(betData)
            }
            state.bets = state.bets.filter(function (item) {
                return true
            })
        },
        SET_BETS (state, betData) {
            /*
            for (var i = 0; i < betData.length; i++) {
                var bet = betData[i]
                bet.oldGoalsHome = bet.goalshome
                bet.oldGoalsGuest = bet.goalsguest
            }
             */
            state.bets = betData
        },
        SET_ALL_BETS (state, betData) {
            state.allbets = betData
            //Vue.set(state, 'allbets', betdata)
        },
        /*
        ADD_ALL_BETS (state, betData) {
            state.allbets = state.allbets.concat(betData)
        },
        */
        ADD_BET (state, betData) {
            state.bets.push(betData)
        },
        SET_TABLE (state, tableData) {
            state.positions = tableData
        },
        SET_TABLE_AT_M (state, tableData) {
            state.positions_at_m = tableData
        },
        SET_TABLE_AT_Q (state, tableData) {
            state.positions_at_q = tableData
        },
        SET_TEAMS (state, teamData) {
            state.teams = teamData
        },
        SET_SCHEDULE (state, scheduleData) {
            state.matches = scheduleData
        },
        UPDATE_SCHEDULE (state, data) {
            const updateMatch = state.matches.filter(match => data.HeimId === '' + match.fk_team_home && data.GastId === '' + match.fk_team_guest)
            if (updateMatch.length === 1) {
                var u = updateMatch[0]
                if('' + u.livegoalshome !== data.ToreHeim || '' + u.livegoalsguest !== data.ToreGast) {
                    Vue.set(u, 'livegoalshome', data.ToreHeim)
                    Vue.set(u, 'livegoalsguest', data.ToreGast)
                }
            }
        },
        SET_CURRENT_MATCHDAY_BET (state, day) {
            state.currentMatchdayBet = day
        },
        SET_CURRENT_MATCHDAY_RANKING (state, day) {
            state.currentMatchdayRankingByMatchday = day
        },
        SET_MATCHDAY_COUNT (state, count) {
            state.matchdayCount = count
        },
        SET_USER_DATA (state, userData) {
            state.user = userData
        },
        SET_USER_STATUS (state, status) {
            state.user.status = status
        },
        SET_TOKEN (state, data) {
            const payload = jwt_decode(JSON.stringify(data))
            var token = data[0].token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            state.token = token
            state.user = payload
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                setBGValue('user', JSON.stringify(payload))
                setBGValue('token', token)
            }
            if(payload.lang !== null && payload.lang !== undefined && payload.lang.length > 1) {
                if(payload.lang !== state.language) {
                    state.language = payload.lang
                }
            }
        },
        SET_TOKEN_LOCAL (state, token) {
            const payload = jwt_decode(JSON.stringify(token))
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            state.token = token
            state.user = payload
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                setBGValue('user', JSON.stringify(payload))
                setBGValue('token', token)
            }
            if(payload.lang !== null && payload.lang !== undefined && payload.lang.length > 1) {
                if(payload.lang !== state.language) {
                    state.language = payload.lang
                }
            }
        },
        CLEAR_USER_DATA (state) {
            state.token = null
            state.user = null
            state.geoData = null
        },
        SET_BETGAME (state, data) {
            Vue.$log.debug('store SET_BETGAME ' + data.id)
            state.betgame = data
        },
        SET_MATCHDAY_WINS (state, data) {
            state.matchDayWins = data
        },
        UPDATE_USER_EMAIL(state, data) {
            state.user.email = data.email_new
        },
        UPDATE_USER_NICKNAME(state, data) {
            state.user.nickname = data.nickname
        },
        UPDATE_USER_NOTIFICATIONS(state, data) {
            state.user.push_notifications = data.push_notifications
            state.user.email_notifications = data.email_notifications
            state.user.news_push_notifications = data.news_push_notifications
            state.user.news_email_notifications = data.news_email_notifications
            state.user.chat_push_notifications = data.chat_push_notifications
        },
        UPDATE_USER(state, data) {
            Object.keys(data).forEach(key => state.user[key] = data[key])
        },
        UPDATE_BETGAME(state, data) {
            Object.keys(data).forEach(key => state.betgame[key] = data[key])
        },
        UPDATE_BET_GOALS_HOME(state, data) {
            const userbets = this.state.bets.filter(bet => bet.fk_user === state.user.id && bet.fk_match === data.fk_match && bet.fk_betgame === data.fk_betgame)
            if (userbets.length > 0) {
                const userbet = userbets[0]
                userbet.goalshome = data.goalshome
            }
        },
        UPDATE_BET_GOALS_GUEST(state, data) {
            const userbets = this.state.bets.filter(bet => bet.fk_user === state.user.id && bet.fk_match === data.fk_match && bet.fk_betgame === data.fk_betgame)
            if (userbets.length > 0) {
                const userbet = userbets[0]
                userbet.goalsguest = data.goalsguest
            }
        },
        SET_LAST_BET_SAVE_DATE (state, date) {
            state.lastBetSaveSuccess = date
        },
        SET_LAST_ANSWER_SAVE_DATE (state, date) {
            state.lastAnswerSaveSuccess = date
        },
        SET_MATCHDAY_RANKINGS (state, rankings) {
            if(rankings.length > 0) {
                const md = rankings[0].matchday
                Vue.set(state.matchdayRankings, md, rankings)
            } else {
                state.matchdayRankings = []
            }
        },
        SET_USER_MATCHDAY_RANKINGS (state, rankings) {
            if(rankings.length > 0) {
                for (var i = 0; i < rankings.length; i++) {
                    const md = rankings[i].matchday
                    Vue.set(state.userMatchdayRankings, md, [rankings[i]])
                }
            } else {
                state.userMatchdayRankings = []
            }
        },
        SET_USER_UNSUBSCRIBED (state, date) {
            state.unsubscribed = date
        },
    },
    actions: {
        setTrendBoxSize ({ commit }, data) {
          commit('SET_TRENDBOX_SIZE', data)  
        },
        chatReportMessage ({ commit }, data) {
            const config = { headers: {
                    'Authorization': 'Bearer ' + this.state.chat_token,
                    'Content-Type': 'application/json; charset=utf-8'
                } }
            return axios.post('https://chat.teamtip.net/conversation/message/report/' + data.messageid,
              data, config)
        },
        chatAddNessage ({ commit }, data) {
            const config = { headers: {
                    'Authorization': 'Bearer ' + this.state.chat_token,
                    'Content-Type': 'application/json; charset=utf-8'
                } }
            return axios.post('https://chat.teamtip.net/conversation/message/' + data.chat,
              data.text, config)
        },
        chatReactNessage ({ commit }, data) {
            const config = { headers: {
                    'Authorization': 'Bearer ' + this.state.chat_token,
                    'Content-Type': 'application/json; charset=utf-8'
                } }
            return axios.post('https://chat.teamtip.net/conversation/message/react/' + data.messageid,
              data.data, config)
        },
        chatDeleteMessage ({ commit }, data) {
            const config = { headers: {
                    'Authorization': 'Bearer ' + this.state.chat_token,
                    'Content-Type': 'application/json; charset=utf-8'
                } }
            return axios.delete('https://chat.teamtip.net/conversation/message/delete/' + data.messageid, config)
        },
        chatAddParticipant ({ commit }, data) {
            Vue.$log.debug('store chatAddParticipant ' + JSON.stringify(data))
            const chatid = data.chat
            const config = { headers: {
                'Authorization': 'Bearer ' + this.state.chat_token, 
                'Content-Type': 'application/x-www-form-urlencoded'
            } }
            return axios.post('https://chat.teamtip.net/conversation/add_participant',
              'user_id=' + data.user + '&conversation_id=' + data.chat, config)
            .then(({ data }) => {
                commit('ADD_CHATS_PARTICIPANT', chatid)
            })
            .catch(function (error) {
                Vue.$log.debug('store chatAddParticipant error ' + JSON.stringify(error))
            })
        },
        chatCreateConversation ({ commit }, data) {
            Vue.$log.debug('store chatCreateConversation ' + JSON.stringify(data))
            const config = { headers: { 
                'Authorization': 'Bearer ' + this.state.chat_token, 
                'Content-Type': 'application/json; charset=utf-8' 
            }}
            return axios.post('https://chat.teamtip.net/conversation/create', data, config)
            .then(({ data }) => {
                Vue.$log.debug('store chatCreateConversation ok ' + JSON.stringify(data))
                const temp = JSON.parse(data)
                commit('UPDATE_BETGAME', {'chat': temp._id})
                commit('ADD_CHATS_PARTICIPANT', temp._id)
                APIService.patchBetgame(this.state.betgameId, { chat: temp._id })
            })
            .catch(function (error) {
                Vue.$log.debug('store chatCreateConversation error ' + JSON.stringify(error))
            })
        },
        chatCreateUser ({ commit }, data) {
            Vue.$log.debug('store chatCreateUser ' + JSON.stringify(data))
            const config = { headers: { 
                'Content-Type': 'application/json; charset=utf-8' 
            } }
            return axios.post('https://chat.teamtip.net/user/create', data, config)
            .then(({ data }) => {
                Vue.$log.debug('store chatCreateUser ok ' + JSON.stringify(data))
            })
            .catch(function (error) {
                Vue.$log.debug('store chatCreateUser error ' + JSON.stringify(error))
            })
        },
        chatLoginUser ({ commit }, data) {
            Vue.$log.debug('store chatLoginUser ' + JSON.stringify(data))
            const config = { headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/x-www-form-urlencoded' 
            }}
            return axios.post('https://chat.teamtip.net/user/login', 'user_id=' + data.user + '&password=' + data.password, config)
            .then(({ data }) => {
                Vue.$log.debug('store chatLoginUser ok ' + JSON.stringify(data))
                commit('SET_CHAT_TOKEN', data)
            })
            .catch(function (error) {
                Vue.$log.debug('store chatLoginUser error ' + JSON.stringify(error))
            })
        },
        deleteReaction ({ commit }, data) {
            for(let i=0; i < this.state.chat_items.length; i++) {
                if(this.state.chat_items[i]._id === data.messageid) {
                    const reactions = this.state.chat_items[i].reactions
                    for(let j=0; j < reactions.length; j++) {
                        if(reactions[j].text === data.reaction && reactions[j].user_id === data.userid) {
                            reactions.splice(j, 1)
                        }
                    }
                    this.state.chat_items[i].reactions = reactions
                    break
                }
            }
        },
        fetchChatItems ({ commit }, unused) {
            if(this.state.betgame && this.state.betgame.chat && this.state.betgame.chat !== '' 
              && this.state.chat_token && this.state.chat_token !== '') {
                const cid = this.state.betgame.chat
                const token = this.state.chat_token
                const uid = this.state.user.id
                var url = 'https://chat.teamtip.net/conversation/list/' + cid + '/0/200'
                if(this.state.chat_items.length > 0) {
                    var item = this.state.chat_items[this.state.chat_items.length - 1]
                    if(!item.timestamp && item.time) {
                        item.timestamp = new Date(item.time).getTime()
                    }
                    var lastCheck = Math.round(item.timestamp)
                    for(let i = 0; i < this.state.chat_items.length; i++) {
                        item = this.state.chat_items[i]
                        if(!item.timestamp && item.time) {
                            item.timestamp = new Date(item.time).getTime()
                        }
                        if(item.timestamp > lastCheck) {
                            lastCheck = Math.round(item.timestamp)
                        }
                        if(item.modified_at && item.modified_at > lastCheck) {
                            lastCheck = Math.round(item.modified_at)
                        }
                    }
                    url = 'https://chat.teamtip.net/conversation/list_after/' + cid + '/' + (Math.round(lastCheck / 1000) + 1)
                }
                //Vue.$log.debug('store fetchChatItems ' + url)
                const that = this
                axios.get(url, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(({ data }) => {
                    const temp = JSON.parse(data)
                    if(that.state.chat_items.length > 0) {
                        var count = 0
                        for(let i = 0; i < temp.length; i++) {
                            if(temp[i].user_id !== '' + uid) {
                                that.state.chat_items.filter(item => item._id === temp[i]._id).length === 0 ? count++ : 0
                            }
                        }
                        commit('ADD_CHAT_ITEMS', temp)
                        commit('SET_UNREAD_COUNT', that.state.unreadCount + count)
                    } else {
                        commit('SET_CHAT_ITEMS', temp)
                    }
                }).catch(function (error) {
                    if (!that.state.chats_participant.includes(that.state.betgame.chat) && uid && cid) {
                        axios.post('https://chat.teamtip.net/conversation/add_participant',
                          'user_id=' + uid + '&conversation_id=' + cid, {
                              headers: {
                                  'Authorization': 'Bearer ' + token,
                                  'Content-Type': 'application/x-www-form-urlencoded'
                              }
                          }).then(function (response) {
                            commit('ADD_CHATS_PARTICIPANT', cid)
                        })
                    }
                })
            }
        },
        setUnreadCount ({ commit }, count) {
            commit('SET_UNREAD_COUNT', count)
        },
        addChatsParticipant ({ commit }, data) {
            commit('ADD_CHATS_PARTICIPANT', data)
        },
        setChatToken ({ commit }, token) {
            commit('SET_CHAT_TOKEN', token)
        },
        addRating ({ commit }, data) {
            commit('ADD_RATING', data)
            const ranking_position = this.state.userRanking ? this.state.userRanking.position : 0
            const betgame_size = this.state.liveRankings ? this.state.liveRankings.length : 0
            APIService.postUserRating({'rating': data.rating, 'info': data.info, fk_user: this.state.user.id, 
            'client': data.client, 'version': data.version, 'app_opened': data.appOpened, 
                'review_requested': data.reviewRequested, 'ranking_position': ranking_position, 
                'betgame_size': betgame_size })
            .then(function (response) {
                Vue.$log.debug('postUserRating ok')
            })
            .catch(function (error) {
                Vue.$log.debug('postUserRating error')
            })
        },
        setReviewPopupShown ({ commit }, data) {
            commit('SET_REVIEW_POPUP_SHOWN', data)
        },
        setBetgameTeams ({ commit }, data) {
            commit('SET_BETGAME_TEAMS', data)
        },
        clearSelectedTeams ({ commit }, data) {
            commit('CLEAR_SELECTED_TEAMS', data)
        },
        setSelectedTeams ({ commit }, data) {
            commit('SET_SELECTED_TEAMS', data)
        },
        setCompetitions ({ commit }, data) {
            commit('SET_COMPETITIONS', data)
        },
        setBattleBetSelectedTab ({ commit }, data) {
            commit('SET_BATTLE_BET_SELECTED_TAB', data)
        },
        clearCreateBetgame ({ commit }, data) {
            commit('CLEAR_CREATE_BETGAME', data)
        },
        setCreateBetgame ({ commit }, data) {
            Vue.$log.debug('store.setCreateBetgame ok ' + JSON.stringify(data))
            commit('SET_CREATE_BETGAME', data)
        },
        clearSelectedMatches ({ commit }, data) {
            commit('CLEAR_SELECTED_MATCHES', data)
        },
        setSelectedMatches ({ commit }, data) {
            commit('SET_SELECTED_MATCHES', data)
        },
        setAppVersion ({ commit }, v) {
            commit('SET_APP_VERSION', v)
        },
        setLanguage ({ commit }, l) {
            commit('SET_LANGUAGE', l)
        },
        uploadBetgameIcon ({ commit }, file) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + this.state.token,
                    'x-betgame-id': '' + this.state.betgameId
                }
            }
            return axios.post(this.state.apiUrl + '/upload', file, config)
            .then(({ data }) => {
                commit('SET_BETGAME_ICON', data.filename)
                Vue.$log.debug('uploadBetgameIcon ' + JSON.stringify(data))
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        uploadUserAvatar ({ commit }, file) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + this.state.token,
                    'x-user-id': '' + this.state.user.id
                }
            }
            return axios.post(this.state.apiUrl + '/uploadavatar', file, config)
            .then(({ data }) => {
                commit('SET_USER_AVATAR', data.filename)
                Vue.$log.debug('uploadUserAvatar ' + JSON.stringify(data))
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        updateUserAvatar ({ commit }, a) {
            commit('SET_USER_AVATAR', a)
        },
        getGeoData ({ commit }, unused) {
            axios.get(this.state.apiUrl + '/geoservice', {})
            .then(({ data }) => {
                commit('SET_GEO_DATA', data)
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        changeLanguage ({ commit }, lang) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_lang',
              {
                  id: '' + this.state.user.id,
                  lang: lang
              }, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('SET_LANGUAGE', lang)
            })
        },
        updateBetgameIcon ({ commit }, icon) {
            commit('SET_BETGAME_ICON', icon)
        },
        updateBetgameInvitationCode ({ commit }, code) {
            commit('SET_BETGAME_INVITATION_CODE', code)
        },
        addBetgamesOwner ({ commit }, data) {
            commit('ADD_BETGAMES_OWNER', data)
        },
        addBetgamesParticipant ({ commit }, data) {
            commit('ADD_BETGAMES_PARTICIPANT', data)
        },
        setLastRoute ({ commit }, r) {
            commit('SET_LAST_ROUTE', r)
        },
        setLastFetchSchedule ({ commit }, f) {
            commit('SET_LAST_FETCH_SCHEDULE', f)
        },
        setLastTokenRefreshCheck ({ commit }, c) {
            commit('SET_LAST_TOKEN_REFRESH_CHECK', c)
        },
        setLastTokenRefresh ({ commit }, r) {
            commit('SET_LAST_TOKEN_REFRESH', r)
        },
        updateUserBetgames ({ commit }, u) {
            commit('SET_UPDATE_USER_BETGAMES', u)
        },
        updateAnswerCount ({ commit }, u) {
            commit('SET_UPDATE_ANSWER_COUNT', u)
        },
        updateSidebarComponentKey ({ commit }, s) {
            commit('SET_SIDEBAR_COMPONENT_KEY', this.state.sidebarComponentKey + 1)
        },
        setMatchdayRakingSorting ({ commit }, s) {
            commit('SET_MATCHDAY_RANKING_SORTING', s)
        },
        setCurrentMatchdayBet ({ commit }, matchday) {
            commit('SET_CURRENT_MATCHDAY_BET', matchday)
        },
        setCurrentMatchdayRankingByMatchday ({ commit }, matchday) {
            commit('SET_CURRENT_MATCHDAY_RANKING', matchday)
        },
        setPaddingBottom ({ commit }, data) {
            commit('SET_PADDING_BOTTOM', data)
        },
        setUserSource ({ commit }, src) {
            commit('SET_USER_SOURCE', src)
        },
        setBetgameId ({ commit }, id) {
            commit('SET_BETGAME_ID', id)
        },
        setApiUrl ({ commit }, url) {
            commit('SET_API_URL', url)
        },
        setBetgame ({ commit }, data) {
            commit('SET_BETGAME', data)
        },
        fetchUserAnswers ({ commit }, params) {
            Vue.$log.debug('fetchUserAnswers1: ' + JSON.stringify(this.state.answers))
            const that = this
            return new Promise((resolve, reject) => {
                if(that.state.user) {
                    APIService.getAnsers(that.state.betgameId, that.state.user.id)
                    .then(response => {
                        Vue.$log.debug('fetchUserAnswers2: ' + JSON.stringify(that.state.answers))
                        Vue.$log.debug('fetchUserAnswers3: ' + JSON.stringify(response.data))
                        commit('SET_ANSWERS', response.data)
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
                } else {
                    commit('SET_ANSWERS', [])
                    resolve()
                } 
            });
        },
        fetchQuestions ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.getQuestions(this.state.betgameId, this.state.betgame.season.id)
                .then(response => {
                    var questionsIDs = ''
                    var hasQuestions = false
                    for (var i = 0; i < response.data.length; i++) {
                        const q = response.data[i]
                        if(parseInt(q.fk_betgame) === parseInt(this.state.betgameId)) {
                            questionsIDs += q.id + ','
                            hasQuestions = true
                        }
                    }
                    questionsIDs += '0'
                    commit('SET_QUESTIONS', response.data)
                    if(hasQuestions) {
                        APIService.getSolutions(questionsIDs)
                        .then(response1 => {
                            commit('SET_SOLUTIONS', response1.data)
                            APIService.getAnsers(this.state.betgameId, this.state.user.id)
                            .then(response2 => {
                                commit('SET_ANSWERS', response2.data)
                                resolve()
                            })
                            .catch(error2 => {
                                reject(error2)
                            })
                        })
                        .catch(error1 => {
                            reject(error1)
                        })
                    }
                })
                .catch(error => {
                    reject(error)
                })
            });
        },
        fetchBetsForMatchdayRanking ({ commit }, params) {
            Vue.$log.debug('fetchBetsForMatchdayRanking ' + params)
            if (this.state.matches === null || this.state.matches === undefined || this.state.matches.length === 0) {
                return
            }
            
            const betgame = params[0]
            const matchday = params[1]
            const ignoreMatchday = params.length > 3 ? params[3] : ''
            const seasonId = this.state.betgame.season.id
            
            var matchIDs = ''
            var matchesFound = false
            for (var i = 0; i < this.state.matches.length; i++) {
                const m = this.state.matches[i]
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if (!timeZone) {
                    timeZone = 'Europe/Berlin'
                }
                const valueFromDB = moment.tz(String(m.matchdate), 'Europe/Berlin')
                const md = valueFromDB.clone().tz(timeZone)
                if ((ignoreMatchday === 'ignoreMatchday' || m.matchday === matchday) && ((md < moment().tz(timeZone)) || (isNumeric(m.goalshome) && isNumeric(m.goalsguest)) || (isNumeric(m.livegoalshome) && isNumeric(m.livegoalsguest)))) {
                    matchIDs += m.id + ','
                    matchesFound = true
                }
            }
            
            /*
            var betIsMissing = true 
            if(this.state.betgameUsers !== null && this.state.betgameUsers !== undefined && this.state.betgameUsers.length <= TOP_TEN && 
              this.state.allbets !== null && this.state.allbets !== undefined && this.state.allbets.length > 0 &&
              this.state.bets !== null && this.state.bets !== undefined && this.state.bets.length > 0) {
                betIsMissing = false
                const betgameUsers = this.state.allbets.concat(this.state.bets).concat(this.state.betgameUsers)
                const matchIds = matchIDs.split(',')
                for (var i = 0; i < betgameUsers.length && !betIsMissing; i++) {
                    const fkUser = betgameUsers[i].fk_user
                    for(var j = 0; j < matchIds.length && !betIsMissing; j++) {
                        if(matchIds[j] === '') {
                            continue
                        }
                        const t = this.state.allbets.filter(b => b.fk_match === parseInt(matchIds[j]) && b.fk_user === fkUser)
                        if(t && t.length === 1) {
                        } else {
                            betIsMissing = true
                        }
                    }
                }
            }
            Vue.$log.debug('fetchBetsForMatchdayRanking betIsMissing ' + matchIDs + ' --- ' + betIsMissing + ' --- ' + matchesFound)
            */
            
            matchIDs += '0'
            if (matchesFound) {
                matchIDs = matchIDs.split(',').removeDuplicates().sort().join(',')
                APIService.getBetsByMatches(betgame, matchIDs, seasonId)
                .then(response => {
                    commit('SET_ALL_BETS', response.data)
                })
                .catch(error => {
                })
            }
            
        },
        fetchBetsForMatchdayRankingBattle ({ commit }, params) {
            Vue.$log.debug('fetchBetsForMatchdayRankingBattle ' + params)
            if (this.state.matches === null || this.state.matches === undefined || this.state.matches.length === 0) {
                return
            }
            if (this.state.user === null) {
                return
            }
            
            const betgame = params[0]
            var matchIDs = ''
            var matchesFound = false
            var matchSeason = 0
            for (var i = 0; i < this.state.matches.length; i++) {
                const m = this.state.matches[i]
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if (!timeZone) {
                    timeZone = 'Europe/Berlin'
                }
                const valueFromDB = moment.tz(String(m.matchdate), 'Europe/Berlin')
                const md = valueFromDB.clone().tz(timeZone)
                if (((md < moment().tz(timeZone)) || (isNumeric(m.goalshome) && isNumeric(m.goalsguest)) || (isNumeric(m.livegoalshome) && isNumeric(m.livegoalsguest)))) {
                    matchIDs += m.id + ','
                    matchesFound = true
                    if (matchSeason === 0) {
                        matchSeason = m.fk_season
                    }
                }
            }
            matchIDs += '0'
            if (matchesFound) {
                matchIDs = matchIDs.split(',').removeDuplicates().sort().join(',')
                APIService.getBetsByMatches(betgame, matchIDs, matchSeason)
                .then(response => {
                    commit('SET_ALL_BETS', response.data)
                })
                .catch(error => {
                })
            }
        },
        fetchBetsForMatchdayRankingTeam ({ commit }, params) {
            Vue.$log.debug('fetchBetsForMatchdayRankingTeam ' + params)            
            if (this.state.matches === null || this.state.matches === undefined || this.state.matches.length === 0) {
                return
            }
            if (this.state.user === null) {
                return
            }
            
            const betgame = params[0]
            var matchIDs = ''
            var matchesFound = false
            var matchSeason = 0
            for (var i = 0; i < this.state.matches.length; i++) {
                const m = this.state.matches[i]
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if (!timeZone) {
                    timeZone = 'Europe/Berlin'
                }
                const valueFromDB = moment.tz(String(m.matchdate), 'Europe/Berlin')
                const md = valueFromDB.clone().tz(timeZone)
                if (((md < moment().tz(timeZone)) || (isNumeric(m.goalshome) && isNumeric(m.goalsguest)) || (isNumeric(m.livegoalshome) && isNumeric(m.livegoalsguest)))) {
                    matchIDs += m.id + ','
                    matchesFound = true
                    if (matchSeason === 0) {
                        matchSeason = m.fk_season
                    }
                }
            }
            matchIDs += '0'
            if (matchesFound) {
                matchIDs = matchIDs.split(',').removeDuplicates().sort().join(',')
                APIService.getBetsByMatches(betgame, matchIDs, matchSeason)
                .then(response => {
                    commit('SET_ALL_BETS', response.data)
                })
                .catch(error => {
                })
            }
        },
        fetchLiveRanking ({ commit }, params) {
    
            /*
            const lastFetch = getBGValue('lastFetchLiveRanking_' + params[0] + '_' + params[1] + '_' + params[2])
            if(lastFetch !== undefined && lastFetch !== null) {
                const duration = (new Date() - new Date(lastFetch)) / 1000
                if (duration > this.getters.getCachingDuration) {
                } else {
                    return
                }
            }
    
            setBGValue('lastFetchLiveRanking_' + params[0] + '_' + params[1] + '_' + params[2], new Date())
            */
    
            Vue.$log.debug('fetchLiveRanking ' + this.state.betgame.type + ' --- ' + params)
    
            if(this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_BATTLE_ID) {
                APIService.getLiveRankingBattle(params[0], params[1], params.length > 3 ? params[3] : '', this.state.user.id)
                .then(response => {
                    if(response.data !== null && response.data.length > 0) {
                        commit('SET_LIVE_RANKINGS', response.data)
                    } else {
                        APIService.getRankingBattle(params[0], params[1])
                        .then(response => {
                            commit('SET_LIVE_RANKINGS', response.data)
                        })
                        .catch(error => {
                            Vue.$log.debug('fetchLiveRanking error 1 ' + error)
                        })
                    }
                })
                .catch(error => {
                    Vue.$log.debug('fetchLiveRanking error 2 ' + error)
                })
    
                APIService.getUserRankingBattle(params[0], params[1], this.state.user.id)
                .then(response => {
                    commit('SET_USER_RANKING', response.data)
                    if(response.data !== null && response.data.length > 0) {
                        commit('SET_USER_LIVE_RANKING', response.data)
                    }
                    const pos = response.data && response.data.length > 0 ? response.data[0].position : DASHBOARD_RANKINGS_SIZE
                    APIService.getLiveRankingsByPositionBattle(params[0], params[1], pos)
                    .then(response => {
                        commit('SET_USER_NEIGHBOUR_RANKINGS', response.data)
                    })
                    .catch(error => {
                        Vue.$log.debug('fetchLiveRanking error 4 ' + error)
                    })
                })
                .catch(error => {
                    Vue.$log.debug('fetchLiveRanking error 5 ' + error)
                })
            } else if(this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_TEAMS_ID) {
                APIService.getLiveRankingTeam(params[0], params[1], params.length > 3 ? params[3] : '', this.state.user.id)
                .then(response => {
                    if(response.data !== null && response.data.length > 0) {
                        commit('SET_LIVE_RANKINGS', response.data)
                    } else {
                        APIService.getRankingTeam(params[0], params[1])
                        .then(response => {
                            commit('SET_LIVE_RANKINGS', response.data)
                        })
                        .catch(error => {
                            Vue.$log.debug('fetchLiveRanking error 1 ' + error)
                        })
                    }
                })
                .catch(error => {
                    Vue.$log.debug('fetchLiveRanking error 2 ' + error)
                })
                
                APIService.getUserRankingTeam(params[0], params[1], this.state.user.id)
                .then(response => {
                    commit('SET_USER_RANKING', response.data)
                    if(response.data !== null && response.data.length > 0) {
                        commit('SET_USER_LIVE_RANKING', response.data)
                    }
                    const pos = response.data && response.data.length > 0 ? response.data[0].position : DASHBOARD_RANKINGS_SIZE
                    APIService.getLiveRankingsByPositionTeam(params[0], params[1], pos)
                    .then(response => {
                        commit('SET_USER_NEIGHBOUR_RANKINGS', response.data)
                    })
                    .catch(error => {
                        Vue.$log.debug('fetchLiveRanking error 4 ' + error)
                    })
                })
                .catch(error => {
                    Vue.$log.debug('fetchLiveRanking error 5 ' + error)
                })
            } else if(this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_BETGAME_ID) {
                APIService.getLiveRanking(params[0], params[1], params.length > 3 ? params[3] : '', this.state.user.id, this.state.betgame.season.id)
                .then(response => {
                    if(response.data !== null && response.data.length > 0) {
                        commit('SET_LIVE_RANKINGS', response.data)
                        const u = response.data.filter(r => r.fk_user === this.state.user.id)
                        if (u !== null && u.length === 1) {
                            Vue.$log.debug('fetchLiveRanking user TOP_TEN ' + JSON.stringify(u))
                            commit('SET_USER_RANKING', u)
                            commit('SET_USER_LIVE_RANKING', u)
                            const pos = u[0].position
                            var t = []
                            const min = pos - 6 > 0 ? pos - 6 : 0
                            if(pos + 5 < response.data.length) {
                                const max = pos + 5 < response.data.length ? pos + 5 : response.data.length
                                for (var i = min; i < max; i++) {
                                    t.push(response.data[i])
                                }
                                commit('SET_USER_NEIGHBOUR_RANKINGS', t)   
                            } else {
                                Vue.$log.debug('fetchLiveRanking fetch SET_USER_NEIGHBOUR_RANKINGS ' + pos)
                                APIService.getLiveRankingsByPosition(params[0], params[1], pos, this.state.betgame.season.id)
                                .then(response => {
                                    commit('SET_USER_NEIGHBOUR_RANKINGS', response.data)
                                })
                                .catch(error => {
                                })
                            }
                        } else {
                            Vue.$log.debug('fetchLiveRanking user NOT TOP_TEN !!! ' + this.state.user.id)
                            APIService.getUserRanking(params[0], params[1], this.state.user.id, this.state.betgame.season.id)
                            .then(response => {
                                commit('SET_USER_RANKING', response.data)
                                if (response.data !== null && response.data.length > 0) {
                                    commit('SET_USER_LIVE_RANKING', response.data)
                                    commit('ADD_LIVE_RANKINGS', response.data)
                                }
                                const pos = response.data && response.data.length > 0 ? response.data[0].position : DASHBOARD_RANKINGS_SIZE
                                APIService.getLiveRankingsByPosition(params[0], params[1], pos, this.state.betgame.season.id)
                                .then(response => {
                                    commit('SET_USER_NEIGHBOUR_RANKINGS', response.data)
                                })
                                .catch(error => {
                                })
                            })
                            .catch(error => {
                            })
                        }
                    } else {
                        APIService.getRanking(params[0], params[1], this.state.betgame.season.id)
                        .then(response => {
                            commit('SET_LIVE_RANKINGS', response.data)
                        })
                        .catch(error => {
                        })
                    }
                })
                .catch(error => {
                })
            }
        },
        fetchMatchdayRanking ({ commit }, params) {
            
            Vue.$log.debug('fetchMatchdayRanking ' + params)
            
            const tournaments = [36,999]
            if(this.state.betgame && this.state.betgame.competition && tournaments.includes(this.state.betgame.competition.id)) {
                return
            }
        
            APIService.getMatchdayRanking(params[0], params[1], this.state.betgame.season.id, params[2], TOP_TEN, params.length > 3 ? params[3] : '', this.state.user.id)
            .then(response => {
                commit('SET_MATCHDAY_RANKINGS', response.data)
    
                var fetchBetsUsers = ''
                var fetchLiveRankingUsers = ''
                for (var i = 0; i < TOP_TEN && i < response.data.length; i++) {
                    const u = response.data[i].fk_user
                    const b = this.state.allbets.filter(bet => bet.fk_user === u)
                    //const r = this.state.liveRankings.filter(ranking => ranking.fk_user === u)
                    if(b === undefined || b === null || b.length === 0) {
                        fetchBetsUsers += u + ','
                    }
                    //if(r === undefined || r === null || r.length === 0) {
                        fetchLiveRankingUsers += u + ','
                    //}
                }
                
                if(fetchLiveRankingUsers !== '') {
                    fetchLiveRankingUsers += '0' //this.state.user.id
                    fetchLiveRankingUsers = fetchLiveRankingUsers.split(",").removeDuplicates().sort().join(",")
                    APIService.getLiveRankingByUsers(params[0], params[1], fetchLiveRankingUsers, this.state.betgame.season.id)
                    .then(response => {
                        commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [params[2], response.data])
                    })
                    .catch(error => {
                    })
                }
            })
            .catch(error => {
            })
    
            //if(this.state.userMatchdayRankings.length === 0) {
                APIService.getMatchdayRankingsByUser(params[0], params[1], this.state.betgame.season.id, this.state.user.id)
                .then(response => {
                    if (response.data !== null) {
                        commit('SET_USER_MATCHDAY_RANKINGS', response.data)
                    }
                })
                .catch(error => {
                })
            //}
            
        },
        fetchBets ({ commit }, userId) {
            APIService.getBets(this.state.betgameId, userId)
                    .then(response => {
                        commit('SET_BETS', response.data)
                    })
                    .catch(error => {
                    })
        },
        fetchUserBetgames ({ commit }, userId) {
            APIService.getUserBetgames(userId)
            .then(response => {
                const bg1 = response.data.betgames_owner
                const bg2 = response.data.betgames_author
                const bg3 = response.data.betgames_participant
                const missing_bets = response.data.missing_bets
                const battles = response.data.battles
                const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                    return bg1.indexOf(item) === -1
                })
                const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                    return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                })
                commit('SET_BATTLES', battles)
                commit('SET_BETGAMES_OWNER', bg1)
                commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                commit('SET_MISSING_BETS', missing_bets)
                var ids = ''
                for (var i = 0; i < bg1.length; i++) {
                    const bg = bg1[i].id
                    ids += bg + ','
                }
                for (var i = 0; i < uniqueArrayAuthor.length; i++) {
                    const bg = uniqueArrayAuthor[i].id
                    ids += bg + ','
                }
                for (var i = 0; i < uniqueArrayParticipant.length; i++) {
                    const bg = uniqueArrayParticipant[i].id
                    ids += bg + ','
                }
                ids += '0'
                APIService.getBetgames(ids, userId)
                .then(response => {
                    commit('SET_USER_BETGAMES', response.data)
                })
                .catch(error => {
                })
                APIService.getBetgameCompetitions(ids)
                .then(response => {
                    commit('SET_BETGAME_COMPETITIONS', response.data)
                })
                .catch(error => {
                })
                APIService.getBetgameInfos(ids)
                .then(response => {
                    commit('SET_BETGAME_INFOS', response.data)
                })
                .catch(error => {
                })
            })
            .catch(error => {
            })
        },
        /*
        fetchBetsOfLiveTopTen ({ commit }, betgameId) {
            var users = ''
            for (var i = 0; i < TOP_TEN && this.state.liveRankings.length; i++) {
                const r = this.state.liveRankings[i]
                users += r.fk_user + ','
            }
            users += this.state.user.id
            APIService.getBetsByUsers(betgameId, users)
            .then(response => {
                commit('SET_ALL_BETS', response.data)
            })
            .catch(error => {
            })
        },
         */
        fetchTable ({ commit }, leagueId) {
            APIService.getTableData(leagueId, this.state.betgame.season.id)
            .then(response => {
                commit('SET_TABLE', response.data)
            })
            .catch(error => {
                Vue.$log.debug('fetchtable error ' + error)
            })
            /*
            if(leagueId === 13) {
                APIService.getTableData(leagueId, this.state.betgame.season.id + '_m')
                .then(response => {
                    commit('SET_TABLE_AT_M', response.data)
                })
                .catch(error => {
                    Vue.$log.debug('fetchtable at m error ' + error)
                })
                APIService.getTableData(leagueId, this.state.betgame.season.id + '_q')
                .then(response => {
                    commit('SET_TABLE_AT_Q', response.data)
                })
                .catch(error => {
                    Vue.$log.debug('fetchtable at q error ' + error)
                })
            }
            */
            //if(window.localStorage.token) {
            //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.token
            //}
            /*
            APIService.getTable(leagueId, this.state.betgame.season.id)
                    .then(response => {
                        commit('SET_TABLE', response.data)
                    })
                    .catch(error => {
                    })
            */
        },
        fetchSpecialQuestionTeams ({ commit }, unused) {
            Vue.$log.debug('fetchSpecialQuestionTeams ')
            const betgames = [58006]
            if(betgames.includes(this.state.betgame.id)) {
                APIService.getTeamsBySportsType(5).then(response => {
                    const teams = []
                    const temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].is_placeholder === 0) {
                            if (!temp.includes(response.data[i].id)) {
                                temp.push(response.data[i].id)
                                teams.push(response.data[i])
                            }
                        }
                    }
                    Vue.$log.debug('fetchSpecialQuestionTeams getTeamsBySportsType ok ' + teams.length)
                    commit('SET_SPECIAL_QUESTION_TEAMS', teams)
                }).catch(error => {
                    Vue.$log.debug('fetchSpecialQuestionTeams getTeamsBySportsType error ' + error)
                })
            } else {
                var leagues = ''
                for (var i = 0; i < this.state.betgameTeamsCompetitions.length; i++) {
                    const c = this.state.betgameTeamsCompetitions[i]
                    leagues += c + ','
                }
                leagues += '0'
                Vue.$log.debug('fetchSpecialQuestionTeams ' + leagues + ' --- ' + this.state.betgame.season.id)
                APIService.getTeamsByLeagues(leagues, this.state.betgame.season.id).then(response => {
                    const teams = []
                    const temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].bg_team && response.data[i].bg_team.is_placeholder === 0) {
                            if (!temp.includes(response.data[i].bg_team.id)) {
                                temp.push(response.data[i].bg_team.id)
                                teams.push(response.data[i].bg_team)
                            }
                        }
                    }
                    Vue.$log.debug('fetchSpecialQuestionTeams ok ' + teams.length)
                    commit('SET_SPECIAL_QUESTION_TEAMS', teams)
                }).catch(error => {
                    Vue.$log.debug('fetchSpecialQuestionTeams error ' + error)
                })
            }
        },
        fetchAllTeams ({ commit }, unused) {
            Vue.$log.debug('fetchAllTeams ')
            APIService.getTeams('').then(response => {
                commit('SET_TEAMS', response.data)
            }).catch(error => {
                Vue.$log.debug('fetchAllTeams error ' + error)
            })
        },
        fetchTeams ({ commit }, betgameId) {
            Vue.$log.debug('fetchTeams ' +  this.state.leagueId)
            if(this.state.leagueId && this.state.leagueId > 0 && this.state.betgame && this.state.betgame.season) {
                APIService.getLeagueTeams(this.state.leagueId, this.state.betgame.season.id)
                .then(response => {
                    const temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        if(response.data[i].bg_team) {
                            temp.push(response.data[i].bg_team)
                        }
                    }
                    commit('SET_TEAMS', temp)
                })
                .catch(error => {
                })
            } else {
                APIService.getTeams(betgameId)
                .then(response => {
                    commit('SET_TEAMS', response.data)
                })
                .catch(error => {
                })
            }
        },
        fetchCurrentSeason ({ commit }, betgameId) {
            APIService.getCurrentSeason()
            .then(response => {
                commit('SET_CURRENT_SEASON', response.data[0])
            })
            .catch(error => {
            })
        },
        fetchMatchdayWins ({ commit }, betgameId) {
            APIService.getMatchdayWins(this.state.leagueId, betgameId, this.state.betgame.season.id)
            .then(response => {
                commit('SET_MATCHDAY_WINS', response.data)
            })
            .catch(error => {
            })
        },
        checkDeleteBetgame ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                const userid = this.state.user.id
                APIService.getOthersBets(betgameId, userid)
                .then(response => {
                    if(response.data.length > 0) {
                        reject('Bets ' + JSON.stringify(response.data))
                    } else {
                        APIService.getOthersAnswers(betgameId, userid)
                        .then(res => {
                            if(res.data.length > 0) {
                                reject('Answers ' + JSON.stringify(res.data))
                            } else {
                                resolve()
                            }
                        })
                        .catch(error => {
                            //reject(error)
                        })
                    }
                })
                .catch(error => {
                })
            });
        },
        userEnterBetgame ({ commit }, betgameId) {
            Vue.$log.debug('userEnterBetgame ' + betgameId + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                APIService.userEnterBetgame(betgameId, this.state.user.id)
                .then(response => {
                    Vue.$log.debug('userEnterBetgame ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('userEnterBetgame error ' + error)
                    reject()
                })
            });
        },
        removeUserFromBetgame({ commit }, data) {
            const that = this
            return new Promise((resolve, reject) => {
                APIService.removeUserFromBetgame(data.user, data.betgame)
                .then(response => {
                    var temp = []
                    for (var i = 0; i < that.state.betgameUsers.length; i++) {
                        const u = that.state.betgameUsers[i]
                        if('' + u.fk_user !== '' + data.user) {
                            temp.push(u)
                        }
                    }
                    commit('SET_BETGAME_USERS', temp)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('removeUserFromBetgame error ' + error)
                    reject()
                })
            })
        },
        userLeaveBetgame ({ commit }, betgameId) {
            Vue.$log.debug('userLeaveBetgame ' + betgameId + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                const userid = this.state.user.id
                const betgameIdTemp = this.state.betgameId
                APIService.deleteUserAnswersByBetgame(betgameId, userid)
                .then(response => {
                    Vue.$log.debug('userLeaveBetgame ok')
                    APIService.deleteUserBetsByBetgame(betgameId, userid)
                    .then(response => {
                        APIService.deleteBetgameAuthor(betgameId, userid)
                        .then(response => {
                            APIService.deleteUserBetgamePoints({ fk_user: userid, fk_betgame: betgameId })
                            .then(function (response) {
                                APIService.deleteUserBetgameRelation(betgameId, userid)
                                .then(response => {
                                    Vue.$log.debug('userLeaveBetgame ok')
                                    if (betgameIdTemp === betgameId) {
                                        commit('SET_BETGAME_ID', 0)
                                        commit('SET_BETGAME', 0)
                                        window.localStorage.removeItem('spmbgid')
                                        if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                            window.localStorage.removeItem('betgameId')
                                        }
                                    }
                                    resolve()
                                })
                                .catch(error => {
                                    Vue.$log.debug('userLeaveBetgame deleteUserBetgameRelation error ' + error)
                                    reject()
                                })
                            })
                            .catch(error => {
                                Vue.$log.debug('userLeaveBetgame deleteUserBetgamePoints error ' + error)
                                reject()
                            })
                        })
                        .catch(error => {
                            Vue.$log.debug('userLeaveBetgame deleteBetgameAuthor error ' + error)
                            reject()
                        })
                    })
                    .catch(error => {
                        Vue.$log.debug('userLeaveBetgame deleteUserBetsByBetgame error ' + error)
                        reject()
                    })
                })
                .catch(error => {
                    Vue.$log.debug('userLeaveBetgame deleteUserAnswersByBetgame error ' + error)
                    reject()
                })
            })
        },
        fetchTournamentRounds ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.fetchTournamentRounds(data.fk_competition, data.fk_season)
                .then(response => {
                    var result = []
                    for (var i = 0; i < response.data.length; i++) {
                        const r = response.data[i]
                        r.name_int = r.name.replace('Vorrunde', 'First round').replace('Vorrunde', 'First round')
                        .replace('Achtelfinale', 'Last 16').replace('Viertelfinale', 'Quarter finals')
                        .replace('Halbfinale', 'Semi finals').replace('3. Platz', '3rd place').replace('Finale', 'Finals')
                        result.push(r)
                    }
                    commit('SET_TOURNAMENT_ROUNDS', result)
                    var groupRounds = ''
                    var allRounds = ''
                    for(var i=0; i<response.data.length; i++) {
                        const temp = response.data[i]
                        allRounds += temp.id + ','
                        if(temp.type === 1) {
                            groupRounds += temp.id + ','
                        }
                    }
                    groupRounds += '0'
                    allRounds += '0'
                    APIService.fetchTournamentMatches(allRounds)
                    .then(response => {
                        commit('SET_TOURNAMENT_MATCHES', response.data)
                        resolve()
                    })
                    .catch(error => {
                        Vue.$log.debug('fetchTournamentMatches error ' + error)
                        reject()
                    })
                    APIService.fetchTournamentGroups(groupRounds)
                    .then(groupresponse => {
                        var result = []
                        for (var i = 0; i < groupresponse.data.length; i++) {
                            const r = groupresponse.data[i]
                            r.name_int = r.name.replace('Gruppe', 'Group')
                            result.push(r)
                        }
                        commit('SET_TOURNAMENT_GROUPS', result)
                        var groupTables = ''
                        for(var i=0; i<groupresponse.data.length; i++) {
                            const temp = groupresponse.data[i]
                            groupTables += temp.id + ','
                        }
                        groupTables += '0'
                        APIService.fetchTournamentGroupTables(groupTables)
                        .then(tableresponse => {
                            commit('SET_TOURNAMENT_GROUP_TABLESS', tableresponse.data)
                            resolve()
                        })
                        .catch(error => {
                            Vue.$log.debug('fetchTournamentGroupTables error ' + error)
                            reject()
                        })
                    })
                    .catch(error => {
                        Vue.$log.debug('fetchTournamentGroups error ' + error)
                        reject()
                    })
                })
                .catch(error => {
                    Vue.$log.debug('fetchTournamentRounds error ' + error)
                    reject()
                })
            });
        },
        fetchBetgame ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameWithLeague(betgameId)
                .then(response => {
                    Vue.$log.debug('store fetchBetgame betgame: ' + JSON.stringify(response.data[0].bg_betgame))
                    Vue.$log.debug('store fetchBetgame season ' + JSON.stringify(response.data[0].bg_season))
                    commit('SET_BETGAME', response.data[0].bg_betgame)
                    const leagueId = response.data[0].bg_competition.id
                    Vue.$log.debug('strore fetchBetgame leagueid ' + leagueId)
                    commit('SET_BETGAME_COMPETITION', response.data[0].bg_competition)
                    commit('SET_BETGAME_SEASON', response.data[0].bg_season)
                    Vue.$log.debug('strore fetchBetgame competition ' + JSON.stringify(response.data[0].bg_competition))
                    commit('SET_LEAGUE_ID', leagueId)
                    resolve(leagueId)
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgame error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameLeague ({ commit }, betgameId) {
            if(this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_BATTLE_ID) {
                Vue.$log.debug('store fetchBetgameLeague skipped battle')
                return 
            }
            if(this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_TEAMS_ID) {
                Vue.$log.debug('store fetchBetgameLeague skipped teams')
                return
            }
            return new Promise((resolve, reject) => {
                APIService.getBetgameLeague(betgameId)
                .then(response => {
                    Vue.$log.debug('store fetchBetgameLeague ' + JSON.stringify(response.data[0].bg_season))
                    const leagueId = response.data[0].bg_competition.id
                    //var temp = this.state.betgame
                    //temp.competition = response.data[0].bg_competition
                    commit('SET_BETGAME_COMPETITION', response.data[0].bg_competition)
                    commit('SET_BETGAME_SEASON', response.data[0].bg_season)
                    //commit('SET_BETGAME', temp)
                    commit('SET_LEAGUE_ID', leagueId)
                    resolve(leagueId)
                })
                .catch(error => {
                    //reject(error)
                })
            })
        },
        fetchMatchBets ({ commit }, competition) {
            const matches = this.state.matches
            var matchIDs = ''
            for (var i = 0; i < matches.length; i++) {
                const bm = matches[i]
                if(!bm.goalshome && !bm.goalsguest && !bm.livegoalshome && !bm.livegoalsguest ) {
                    matchIDs += bm.id + ","
                }
            }
            matchIDs += '0'
            matchIDs = matchIDs.split(",").removeDuplicates().sort().join(",")
            APIService.getBetOdds(matchIDs)
            .then(response => {
                Vue.$log.debug('fetchMatchBets SET_MATCH_BETS bg_match_bet count ' + response.data.length)
                commit('SET_MATCH_BETS', response.data)
            })
            .catch(error => {
                Vue.$log.debug('fetchMatchBets bg_match_bet ' + error)
            })
        },
        fetchMatchdaySchedule ({ commit }, params) {
            if(!this.state.betgame || this.state.betgame.season === undefined || this.state.betgame.season === null) {
                return
            }
            Vue.$log.debug('fetchMatchdaySchedule ' + params)
            APIService.getSchedule(params[0], this.state.betgame.season.id)
            .then(response => {
                const fetchedMatches = response.data.filter(s => parseInt(s.matchday) === parseInt(params[1]))
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if(!timeZone) {
                    timeZone = 'Europe/Berlin'
                }
                const now = moment().tz(timeZone)
                var runningMatches = []
                var schedule = []
                for (var i = 0; i < this.state.matches.length; i++) {
                    const match = this.state.matches[i]
                    const updateMatch = fetchedMatches.filter(s => s.id === match.id)
                    if(updateMatch.length === 1) {
                        schedule.push(updateMatch[0])
                    } else {
                        schedule.push(match)
                    }
                    const valueFromDB = moment.tz(String(schedule[i].matchdate), "Europe/Berlin")
                    const matchdate = valueFromDB.clone().tz(timeZone)
                    if (matchdate < now) {
                        var diff = now - matchdate
                        const minutesDiff = diff/(1000*60)
                        if(minutesDiff < 135 || (isNumeric(schedule[i].livegoalshome) && isNumeric(schedule[i].livegoalsguest))) {
                            runningMatches.push(schedule[i].id)
                        }
                    }
                }
                commit('SET_SCHEDULE', schedule)
                commit('SET_RUNNING_MATCHES', runningMatches)
            })
            .catch(error => {
            })
        },
        fetchSchedule ({ commit }, leagueId) {
            const leaguesScheduled = [1,2,3,13,14,19,33,36,71,76,87,91,97,107,111,115,116,132,188,189,564,998,999,1110,1379,2255,2256,2257,4228,6395]
            const leaguesScheduled15 = [0,1,2,3,13,14,19,33,71,76,87,91,97,107,111,115,116,132,188,189,564,998,999,1379,2255,2256,2257,4228,6395]
            Vue.$log.debug('fetchSchedule leagueId ' + leagueId + ' --- this.state.betgame ' + JSON.stringify(this.state.betgame))
            if (!this.state.betgame || this.state.betgame.season === undefined || this.state.betgame.season === null ||
              (!leaguesScheduled.includes(leagueId) && leagueId !== 0 && this.state.betgame.season.id === 14)) {
                Vue.$log.debug('fetchSchedule this.state.betgame.season NOT FOUND')
                return
            }
            if(this.state.betgame && this.state.betgame.season && this.state.betgame.season.id === 15 && !leaguesScheduled15.includes(leagueId)) {
                Vue.$log.debug('fetchSchedule this.state.betgame.season 15')
                return
            }
            setBGValue('spmlfs', new Date())
            if (parseInt(leagueId) === 0 && (this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_TEAMS_ID)) {
                if(!this.state.betgameTeams || this.state.betgameTeams.length === 0) {
                    this.dispatch('fetchBetgameTeams', this.state.betgame.id).then(() => {
                        var teams = ''
                        for (var i = 0; i < this.state.betgameTeams.length; i++) {
                            teams += this.state.betgameTeams[i].fk_team + ","
                        }
                        teams += '0'
                        APIService.getTeamsMatches(teams, this.state.betgame.season.id).then(response=> {
                            Vue.$log.debug('fetchSchedule teams getTeamsMatches length ' + JSON.stringify(response.data.length))
                            var arr = []
                            var temp = []
                            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                            if (!timeZone) {
                                timeZone = 'Europe/Berlin'
                            }
                            const now = moment().tz(timeZone)
                            var pastDiff = Infinity
                            var futureDiff = Infinity
                            var nearestPastDate = null
                            var nearestFutureDate = null
                            var pastMatchday = 1
                            var futureMatchday = 1
                            var runningMatches = []
                            var runningMatchday = 1
                            var todaysMatches = []
                            var maxStartedMatchday = 1
                            var betgameCompetitions = []
                            for (var i = 0; i < response.data.length; i++) {
                                const response1datai = response.data[i]
                                temp.push(response1datai)
                                //var matchdate = Date.parse(response1datai.matchdate)
                                //var matchdate = moment(response1datai.matchdate, MATCH_DATE_FORMAT, true)
                                const valueFromDB = moment.tz(String(response1datai.matchdate), 'Europe/Berlin')
                                const matchdate = valueFromDB.clone().tz(timeZone)
                                if (isToday(new Date(matchdate))) {
                                    todaysMatches.push(response1datai.id)
                                }
                                if(betgameCompetitions.indexOf(response1datai.fk_competition) === -1) {
                                    betgameCompetitions.push(response1datai.fk_competition)
                                }
                                if ((isNumeric(response1datai.goalshome) && isNumeric(response1datai.goalsguest)) ||
                                  (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                                    if (response1datai.matchday > maxStartedMatchday &&
                                      response1datai.status !== 'Wertung') {
                                        maxStartedMatchday = response1datai.matchday
                                    }
                                }
                                if (matchdate < now) {
                                    const diff = now - matchdate
                                    const minutesDiff = diff / (1000 * 60)
                                    if (minutesDiff < 135 || (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                                        runningMatches.push(response1datai.id)
                                        runningMatchday = response1datai.matchday
                                    }
                                    if (diff < pastDiff) {
                                        pastDiff = diff
                                        nearestPastDate = matchdate
                                        pastMatchday = response1datai.matchday
                                    }
                                } else if (matchdate > now) {
                                    const diff = matchdate - now
                                    if (diff < futureDiff) {
                                        futureDiff = diff
                                        nearestFutureDate = matchdate
                                        futureMatchday = response1datai.matchday
                                    }
                                }
                                arr.push(response1datai.matchday)
                            }
                            
                            temp.sort(function (a, b) {
                                return b.matchdate.localeCompare(a.matchdate)
                            })
                            
                            const maxMatchday = (leagueId === 10700 || leagueId === 36) ? 7 : Math.max(...arr)
                            
                            if (runningMatches.length > 0) {
                                Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET3 ' + runningMatchday)
                                commit('SET_CURRENT_MATCHDAY_BET', runningMatchday)
                            } else {
                                Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET4 ' + futureMatchday)
                                commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                            }
                            
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_RANKING ' + maxStartedMatchday)
                            Vue.$log.debug('fetchSchedule SET_MATCHDAY_COUNT ' + maxMatchday)
                            //Vue.$log.debug('fetchSchedule SET_SCHEDULE ' + JSON.stringify(temp))
                            Vue.$log.debug('fetchSchedule SET_TODAYS_MATCHES ' + JSON.stringify(todaysMatches))
                            Vue.$log.debug('fetchSchedule SET_RUNNING_MATCHES ' + JSON.stringify(runningMatches))
                            Vue.$log.debug('fetchSchedule SET_BETGAME_TEAMS_COMPETITIONS ' + JSON.stringify(betgameCompetitions))
                            
                            commit('SET_MAX_STARTED_MATCHDAY', maxStartedMatchday)
                            commit('SET_CURRENT_MATCHDAY_RANKING', maxStartedMatchday)
                            commit('SET_MATCHDAY_COUNT', maxMatchday)
                            commit('SET_SCHEDULE', temp)
                            commit('SET_TODAYS_MATCHES', todaysMatches)
                            commit('SET_RUNNING_MATCHES', runningMatches)
                            commit('SET_BETGAME_TEAMS_COMPETITIONS', betgameCompetitions)
                        }).catch(function (error) {
                            Vue.$log.debug('store fetchSchedule teams ' + error)
                        })
                    })
                } else {
                    var teams = ''
                    for (var i = 0; i < this.state.betgameTeams.length; i++) {
                        teams += this.state.betgameTeams[i].fk_team + ","
                    }
                    teams += '0'
                    APIService.getTeamsMatches(teams, this.state.betgame.season.id).then(response=> {
                        Vue.$log.debug('fetchSchedule teams getTeamsMatches length ' + JSON.stringify(response.data.length))
                        var arr = []
                        var temp = []
                        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                        if (!timeZone) {
                            timeZone = 'Europe/Berlin'
                        }
                        const now = moment().tz(timeZone)
                        var pastDiff = Infinity
                        var futureDiff = Infinity
                        var nearestPastDate = null
                        var nearestFutureDate = null
                        var pastMatchday = 1
                        var futureMatchday = 1
                        var runningMatches = []
                        var runningMatchday = 1
                        var todaysMatches = []
                        var maxStartedMatchday = 1
                        var betgameCompetitions = []
                        for (var i = 0; i < response.data.length; i++) {
                            const response1datai = response.data[i]
                            temp.push(response1datai)
                            //var matchdate = Date.parse(response1datai.matchdate)
                            //var matchdate = moment(response1datai.matchdate, MATCH_DATE_FORMAT, true)
                            const valueFromDB = moment.tz(String(response1datai.matchdate), 'Europe/Berlin')
                            const matchdate = valueFromDB.clone().tz(timeZone)
                            if (isToday(new Date(matchdate))) {
                                todaysMatches.push(response1datai.id)
                            }
                            if(betgameCompetitions.indexOf(response1datai.fk_competition) === -1) {
                                betgameCompetitions.push(response1datai.fk_competition)
                            }
                            if ((isNumeric(response1datai.goalshome) && isNumeric(response1datai.goalsguest)) ||
                              (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                                if (response1datai.matchday > maxStartedMatchday &&
                                  response1datai.status !== 'Wertung') {
                                    maxStartedMatchday = response1datai.matchday
                                }
                            }
                            if (matchdate < now) {
                                const diff = now - matchdate
                                const minutesDiff = diff / (1000 * 60)
                                if (minutesDiff < 135 || (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                                    runningMatches.push(response1datai.id)
                                    runningMatchday = response1datai.matchday
                                }
                                if (diff < pastDiff) {
                                    pastDiff = diff
                                    nearestPastDate = matchdate
                                    pastMatchday = response1datai.matchday
                                }
                            } else if (matchdate > now) {
                                const diff = matchdate - now
                                if (diff < futureDiff) {
                                    futureDiff = diff
                                    nearestFutureDate = matchdate
                                    futureMatchday = response1datai.matchday
                                }
                            }
                            arr.push(response1datai.matchday)
                        }
                        
                        temp.sort(function (a, b) {
                            return b.matchdate.localeCompare(a.matchdate)
                        })
                        
                        const maxMatchday = (leagueId === 10700 || leagueId === 36) ? 7 : Math.max(...arr)
                        
                        if (runningMatches.length > 0) {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET3 ' + runningMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', runningMatchday)
                        } else {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET4 ' + futureMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                        }
                        
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_RANKING ' + maxStartedMatchday)
                        Vue.$log.debug('fetchSchedule SET_MATCHDAY_COUNT ' + maxMatchday)
                        //Vue.$log.debug('fetchSchedule SET_SCHEDULE ' + JSON.stringify(temp))
                        Vue.$log.debug('fetchSchedule SET_TODAYS_MATCHES ' + JSON.stringify(todaysMatches))
                        Vue.$log.debug('fetchSchedule SET_RUNNING_MATCHES ' + JSON.stringify(runningMatches))
                        Vue.$log.debug('fetchSchedule SET_BETGAME_TEAMS_COMPETITIONS ' + JSON.stringify(betgameCompetitions))
                        
                        commit('SET_MAX_STARTED_MATCHDAY', maxStartedMatchday)
                        commit('SET_CURRENT_MATCHDAY_RANKING', maxStartedMatchday)
                        commit('SET_MATCHDAY_COUNT', maxMatchday)
                        commit('SET_SCHEDULE', temp)
                        commit('SET_TODAYS_MATCHES', todaysMatches)
                        commit('SET_RUNNING_MATCHES', runningMatches)
                        commit('SET_BETGAME_TEAMS_COMPETITIONS', betgameCompetitions)
                    }).catch(function (error) {
                        Vue.$log.debug('store fetchSchedule teams ' + error)
                    })
                }
            } else if (parseInt(leagueId) === 0 && (this.state.betgame && this.state.betgame.type && parseInt(this.state.betgame.type) === TYPE_BATTLE_ID)) {
                APIService.getBetgameMatches(this.state.betgame.id).then(response => {
                    Vue.$log.debug('fetchSchedule battle getBetgameMatches length ' + response.data.length)
                    var arr = []
                    var temp = []
                    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                    if (!timeZone) {
                        timeZone = 'Europe/Berlin'
                    }
                    const now = moment().tz(timeZone)
                    var pastDiff = Infinity
                    var futureDiff = Infinity
                    var nearestPastDate = null
                    var nearestFutureDate = null
                    var pastMatchday = 1
                    var futureMatchday = 1
                    var runningMatches = []
                    var runningMatchday = 1
                    var todaysMatches = []
                    var maxStartedMatchday = 1
                    var betgameCompetitions = []
                    for (var i = 0; i < response.data.length; i++) {
                        const response1datai = response.data[i].bg_v_schedule
                        temp.push(response1datai)
                        //var matchdate = Date.parse(response1datai.matchdate)
                        //var matchdate = moment(response1datai.matchdate, MATCH_DATE_FORMAT, true)
                        const valueFromDB = moment.tz(String(response1datai.matchdate), 'Europe/Berlin')
                        const matchdate = valueFromDB.clone().tz(timeZone)
                        if (isToday(new Date(matchdate))) {
                            todaysMatches.push(response1datai.id)
                        }
                        if(betgameCompetitions.indexOf(response1datai.fk_competition) === -1) {
                            betgameCompetitions.push(response1datai.fk_competition)
                        }
                        if ((isNumeric(response1datai.goalshome) && isNumeric(response1datai.goalsguest)) ||
                          (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                            if (response1datai.matchday > maxStartedMatchday &&
                              response1datai.status !== 'Wertung') {
                                maxStartedMatchday = response1datai.matchday
                            }
                        }
                        if (matchdate < now) {
                            const diff = now - matchdate
                            const minutesDiff = diff / (1000 * 60)
                            if (minutesDiff < 135 || (isNumeric(response1datai.livegoalshome) && isNumeric(response1datai.livegoalsguest))) {
                                runningMatches.push(response1datai.id)
                                runningMatchday = response1datai.matchday
                            }
                            if (diff < pastDiff) {
                                pastDiff = diff
                                nearestPastDate = matchdate
                                pastMatchday = response1datai.matchday
                            }
                        } else if (matchdate > now) {
                            const diff = matchdate - now
                            if (diff < futureDiff) {
                                futureDiff = diff
                                nearestFutureDate = matchdate
                                futureMatchday = response1datai.matchday
                            }
                        }
                        arr.push(response1datai.matchday)
                    }
                
                    temp.sort(function (a, b) {
                        return b.matchdate.localeCompare(a.matchdate)
                    })
                
                    const maxMatchday = (leagueId === 10700 || leagueId === 36) ? 7 : Math.max(...arr)
                
                    if (runningMatches.length > 0) {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET3 ' + runningMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', runningMatchday)
                    } else {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET4 ' + futureMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                    }
                
                    Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_RANKING ' + maxStartedMatchday)
                    Vue.$log.debug('fetchSchedule SET_MATCHDAY_COUNT ' + maxMatchday)
                    //Vue.$log.debug('fetchSchedule SET_SCHEDULE ' + JSON.stringify(temp))
                    Vue.$log.debug('fetchSchedule SET_TODAYS_MATCHES ' + JSON.stringify(todaysMatches))
                    Vue.$log.debug('fetchSchedule SET_RUNNING_MATCHES ' + JSON.stringify(runningMatches))
                    Vue.$log.debug('fetchSchedule SET_BETGAME_TEAMS_COMPETITIONS ' + JSON.stringify(betgameCompetitions))
                
                    commit('SET_MAX_STARTED_MATCHDAY', maxStartedMatchday)
                    commit('SET_CURRENT_MATCHDAY_RANKING', maxStartedMatchday)
                    commit('SET_MATCHDAY_COUNT', maxMatchday)
                    commit('SET_SCHEDULE', temp)
                    commit('SET_TODAYS_MATCHES', todaysMatches)
                    commit('SET_RUNNING_MATCHES', runningMatches)
                    commit('SET_BETGAME_TEAMS_COMPETITIONS', betgameCompetitions)
                })
                .catch(error => {
                    Vue.$log.debug('fetchSchedule getBetgameMatches error ' + error)
                })
            } else {
                APIService.getSchedule(leagueId, this.state.betgame.season.id)
                .then(response => {
                    //Vue.$log.debug('fetchSchedule getSchedule ' + JSON.stringify(response.data))
                    var arr = []
                    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                    if (!timeZone) {
                        timeZone = 'Europe/Berlin'
                    }
                    const now = moment().tz(timeZone)
                    var pastDiff = Infinity
                    var futureDiff = Infinity
                    var nearestPastDate = null
                    var nearestFutureDate = null
                    var pastMatchday = 1
                    var futureMatchday = 1
                    var runningMatches = []
                    var runningMatchday = 1
                    var todaysMatches = []
                    var maxStartedMatchday = 1
                    for (var i = 0; i < response.data.length; i++) {
                        //var matchdate = Date.parse(response.data[i].matchdate)
                        const valueFromDB = moment.tz(String(response.data[i].matchdate), 'Europe/Berlin')
                        const matchdate = valueFromDB.clone().tz(timeZone)
                        if (isToday(new Date(matchdate))) {
                            todaysMatches.push(response.data[i].id)
                        }
                        if ((isNumeric(response.data[i].goalshome) && isNumeric(response.data[i].goalsguest)) ||
                          (isNumeric(response.data[i].livegoalshome) && isNumeric(response.data[i].livegoalsguest))) {
                            if (response.data[i].matchday > maxStartedMatchday &&
                              response.data[i].status !== 'Wertung') {
                                maxStartedMatchday = response.data[i].matchday
                            }
                        }
                        if (matchdate < now) {
                            const diff = now - matchdate
                            const minutesDiff = diff / (1000 * 60)
                            if (minutesDiff < 135 || (isNumeric(response.data[i].livegoalshome) && isNumeric(response.data[i].livegoalsguest))) {
                                runningMatches.push(response.data[i].id)
                                runningMatchday = response.data[i].matchday
                            }
                            if (diff < pastDiff) {
                                pastDiff = diff
                                nearestPastDate = matchdate
                                pastMatchday = response.data[i].matchday
                            }
                        } else if (matchdate > now) {
                            const diff = matchdate - now
                            if (diff < futureDiff) {
                                futureDiff = diff
                                nearestFutureDate = matchdate
                                futureMatchday = response.data[i].matchday
                            }
                        }
                        arr.push(response.data[i].matchday)
                    }
                    
                    const temp = response.data
                    temp.sort(function (a, b) {
                        return b.matchdate.localeCompare(a.matchdate)
                    })
                    
                    const maxMatchday = (leagueId === 10700 || leagueId === 36) ? 7 : Math.max(...arr)
                    /*
                    if (pastDiff < futureDiff) {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET1 ' + pastMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', pastMatchday)
                    } else if (futureDiff < pastDiff) {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET2 ' + futureMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                    }
                    */
                    
                    if (runningMatches.length > 0) {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET3 ' + runningMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', runningMatchday)
                    } else {
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET4 ' + futureMatchday)
                        commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                    }
                    
                    Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_RANKING ' + maxStartedMatchday)
                    Vue.$log.debug('fetchSchedule SET_MATCHDAY_COUNT ' + maxMatchday)
                    //Vue.$log.debug('fetchSchedule SET_SCHEDULE ' + JSON.stringify(temp))
                    Vue.$log.debug('fetchSchedule SET_TODAYS_MATCHES ' + JSON.stringify(todaysMatches))
                    Vue.$log.debug('fetchSchedule SET_RUNNING_MATCHES ' + JSON.stringify(runningMatches))
                    
                    commit('SET_MAX_STARTED_MATCHDAY', maxStartedMatchday)
                    commit('SET_CURRENT_MATCHDAY_RANKING', maxStartedMatchday)
                    commit('SET_MATCHDAY_COUNT', maxMatchday)
                    commit('SET_SCHEDULE', temp)
                    commit('SET_TODAYS_MATCHES', todaysMatches)
                    commit('SET_RUNNING_MATCHES', runningMatches)
                })
                .catch(error => {
                })
            }
        },
        register ({ commit }, credentials) {
            return axios
                    .post(this.state.apiUrl + '/rpc/signup_login', credentials)
                    .then(({ data }) => {
                        //commit('SET_USER_DATA', data)
                        setBGValue('spmltr', new Date())
                        commit('SET_TOKEN', [data.token])
                        commit('SET_BETGAME_ID', data.betgame)
                    })
        },
        registerUserCreateBetgame ({ commit }, val) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/register_user_create_betgame', val)
                .then(({ data }) => {
                    setBGValue('spmltr', new Date())
                    commit('SET_TOKEN', [data.token])
                    window.localStorage.setItem('spmbgid', data.betgame)
                    if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
                        window.localStorage.setItem('betgameId', data.betgame)
                    }
                    commit('SET_BETGAME_ID', data.betgame)
                    resolve(data.betgame)
                }).catch(function (error) {
                    Vue.$log.debug('store registerUserCreateBetgame ' + error.toString())
                    reject(error)
                })
            })
        },
        createBetgame ({ commit }, val) {
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/create_betgame', val, {
                    contentType: "application/json",
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    }})
                .then(({ data }) => {
                    window.localStorage.setItem('spmbgid', data.betgame)
                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                        window.localStorage.setItem('betgameId', data.betgame)
                    }
                    commit('SET_BETGAME_ID', data.betgame)
                    resolve(data.betgame)
                }).catch(function (error) {
                    Vue.$log.debug('strore createBetgame ' + error.toString())
                    reject(error)
                })
            })
        },
        createBetgameAddUsers ({ commit }, val) {
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/create_betgame_add_users', val, {
                    contentType: "application/json",
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    }})
                .then(({ data }) => {
                    window.localStorage.setItem('spmbgid', data.betgame)
                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                        window.localStorage.setItem('betgameId', data.betgame)
                    }
                    commit('SET_BETGAME_ID', data.betgame)
                    resolve(data.betgame)
                }).catch(function (error) {
                    Vue.$log.debug('store createBetgameAddUsers error ' + error.toString())
                    reject(error)
                })
            })
        },
        validateRegistration ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/validate_registration', credentials, {
                    contentType: "application/json"
                })
                .then(({ data }) => {
                    // commit('SET_USER_DATA', data)
                    if(this.state.user && this.state.user !== 'active') {
                        commit('SET_USER_STATUS', 'active')
                        //const u = this.state.user
                        //u.status = 'active'
                        //commit('SET_USER_DATA', u)
                    }
                    resolve()
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        requestPasswordReset ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                return axios
                    .post(this.state.apiUrl + '/rpc/request_password_reset', credentials)
                    .then(({ data }) => {
                        // commit('SET_USER_DATA', data)
                        resolve()
                    }).catch(function (error) {
                        Vue.$log.debug('store requestPasswordReset error ' + JSON.stringify(error))
                        reject(error)
                    })
            })
        },
        resetPassword ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return axios
                    .post(this.state.apiUrl + '/rpc/reset_password_data', credentials)
                    .then(({ data }) => {
                        const bg1 = data.betgames_owner
                        const bg2 = data.betgames_author
                        const bg3 = data.betgames_participant
                        const user_betgame = data.user_betgame
                        const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                            return bg1.indexOf(item) === -1
                        })
                        const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                            return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                        })
                        commit('SET_BETGAMES_OWNER', bg1)
                        commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                        commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                        if(this.state.betgameId === undefined || this.state.betgameId === null || this.state.betgameId === 0) {
                            var bgid = 0
                            if(bg1.length > 0) {
                                bgid  = bg1[0].id
                            } else if(bg2.length > 0) {
                                bgid  = bg2[0].id
                            } else if(bg3.length > 0) {
                                bgid  = bg3[0].id
                            } else if(user_betgame !== null) {
                                bgid  = user_betgame
                            }
                            if(bgid > 0) {
                                window.localStorage.setItem('spmbgid', bgid)
                                if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                    window.localStorage.setItem('betgameId', bgid)
                                }
                                commit('SET_BETGAME_ID', bgid)
                                setBGValue('spmltr', new Date())
                                commit('SET_TOKEN', [data.token])
                            } else {
                                // route search betgame page
                                const id = window.localStorage.getItem('spmbgid')
                                if(id !== undefined && id !== null && id > 0) {
                                    commit('SET_BETGAME_ID', id)
                                    setBGValue('spmltr', new Date())
                                    commit('SET_TOKEN', [data.token])
                                } else {
                                    const payload = jwt_decode(JSON.stringify([data.token]))
                                    const fkbetgame = payload.fk_betgame
                                    if(fkbetgame !== undefined && fkbetgame !== null && fkbetgame > 0) {
                                        window.localStorage.setItem('spmbgid', fkbetgame)
                                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                            window.localStorage.setItem('betgameId', fkbetgame)
                                        }
                                        commit('SET_BETGAME_ID', fkbetgame)
                                        setBGValue('spmltr', new Date())
                                        commit('SET_TOKEN', [data.token])
                                    } else {
                                        window.localStorage.setItem('spmbgid', 0)
                                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                            window.localStorage.setItem('betgameId', 0)
                                        }
                                        setBGValue('spmltr', new Date())
                                        commit('SET_TOKEN', [data.token])
                                    }
                                }
                            }
                        } else {
                            setBGValue('spmltr', new Date())
                            commit('SET_TOKEN', [data.token])
                        }
                    })
        },
        login ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                axios.defaults.withCredentials = true
                axios.post(this.state.apiUrl + '/rpc/login_data', credentials)
                .then(({ data }) => {
                    commit('SET_TOKEN', [data.token])
                    const bg1 = data.betgames_owner
                    const bg2 = data.betgames_author
                    const bg3 = data.betgames_participant
                    const user_betgame = data.user_betgame
                    const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                        return bg1.indexOf(item) === -1
                    })
                    const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                        return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                    })
                    commit('SET_BETGAMES_OWNER', bg1)
                    commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                    commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                    if(this.state.betgameId === undefined || this.state.betgameId === null || this.state.betgameId === 0) {
                        var bgid = 0
                        if(bg1.length > 0) {
                            const id1 = bg1.sort((a, b) => b.id - a.id)[0].id
                            if(id1 > bgid) {
                                bgid = id1
                            }
                        } else if(bg2.length > 0) {
                            const id2 = bg2.sort((a, b) => b.id - a.id)[0].id
                            if(id2 > bgid) {
                                bgid = id2
                            }
                        } else if(bg3.length > 0) {
                            const id3 = bg3.sort((a, b) => b.id - a.id)[0].id
                            if(id3 > bgid) {
                                bgid = id3
                            }
                        } else if(user_betgame !== null && user_betgame > bgid) {
                            bgid  = user_betgame
                        }
                        if(bgid > 0) {
                            window.localStorage.setItem('spmbgid', bgid)
                            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                window.localStorage.setItem('betgameId', bgid)
                            }
                            commit('SET_BETGAME_ID', bgid)
                            setBGValue('spmltr', new Date())
                            resolve(bgid)
                        } else {
                            const id = window.localStorage.getItem('spmbgid')
                            if(id !== undefined && id !== null && id > 0) {
                                commit('SET_BETGAME_ID', id)
                                setBGValue('spmltr', new Date())
                                resolve(id)
                            } else {
                                const payload = jwt_decode(JSON.stringify([data.token]))
                                const fkbetgame = payload.fk_betgame
                                if(fkbetgame !== undefined && fkbetgame !== null && fkbetgame > 0) {
                                    window.localStorage.setItem('spmbgid', fkbetgame)
                                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                        window.localStorage.setItem('betgameId', fkbetgame)
                                    }
                                    commit('SET_BETGAME_ID', fkbetgame)
                                    setBGValue('spmltr', new Date())
                                    resolve(id)
                                } else {
                                    window.localStorage.setItem('spmbgid', 0)
                                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                        window.localStorage.setItem('betgameId', 0)
                                    }
                                    setBGValue('spmltr', new Date())
                                    resolve(0)
                                }
                            }
                        }
                    } else {
                        setBGValue('spmltr', new Date())
                        resolve(this.state.betgameId)
                    }
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        loginLocal ({ commit }, token) {
    
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_USER_BETGAME_POINTS', [])
            commit('SET_POINTS_CORRECTIONS_COUNT', 0)
            commit('SET_LIVETICKER', [])
            commit('SET_LIVE_RESULTS', [])
            commit('SET_TOURNAMENT_ROUNDS', [])
            commit('SET_TOURNAMENT_MATCHES', [])
            commit('SET_TOURNAMENT_GROUPS', [])
            commit('SET_TOURNAMENT_GROUP_TABLESS', [])
            commit('SET_BETGAME_TEAMS', [])
            commit('SET_BETGAME_TEAMS_COMPETITIONS', [])
            commit('SET_BETGAME_RANKINGS', [])
            commit('SET_REVIEW_POPUP_SHOWN', 0)
            commit('SET_SPECIAL_QUESTION_TEAMS', [])
            commit('SET_CHAT_TOKEN', '')
            commit('SET_CHAT_ITEMS', [])
            commit('SET_UNREAD_COUNT',0)
            
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                var tokenExpired = false
                try {
                    const payload = jwt_decode(token)
                    const expDate = payload.exp * 1000
                    if (expDate < Date.now()) {
                        tokenExpired = true
                    }
                } catch (e) {
                    tokenExpired = token
                }
                if(!tokenExpired) {
                    const payload = jwt_decode(JSON.stringify(token))
                    commit('SET_TOKEN_LOCAL', token)
                    resolve(payload)
                } else {
                    reject(token)
                }
            })
        },
        deleteAccount ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/delete_account', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ data }) => {
            })
        },
        resetBetgame ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/reset_betgame', data, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ result }) => {
                    Vue.$log.debug('store resetBetgame success ' + JSON.stringify(result))
                    resolve()
                }).catch(function (error) {
                    Vue.$log.debug('store resetBetgame error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        copyBetgame ({ commit }, values) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/copy_betgame', values, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ data }) => {
                    Vue.$log.debug('store copyBetgame success ' + JSON.stringify(data))
                    resolve(data.betgame)
                }).catch(function (error) {
                    Vue.$log.debug('store copyBetgame error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        deleteAllBets ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/delete_betgame_bets', data, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ result }) => {
                    Vue.$log.debug('store deleteAllBets success ' + JSON.stringify(result))
                    commit('SET_BETS', [])
                    commit('SET_BET_COUNT', -1)
                    resolve()
                }).catch(function (error) {
                    Vue.$log.debug('store deleteAllBets error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        deleteBetgame ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/delete_betgame', data, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ result }) => {
                    if (this.state.betgameId === data.betgame) {
                        commit('SET_BETGAME_ID', 0)
                        commit('SET_BETGAME', 0)
                        window.localStorage.removeItem('spmbgid')
                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                            window.localStorage.removeItem('betgameId')
                        }
                    }
                    var bo = []
                    for (var i = 0; i < this.state.betgames_owner.length; i++) {
                        const bg = this.state.betgames_owner[i]
                        if (bg.id !== data.betgame) {
                            bo.push(bg)
                        }
                    }
                    commit('SET_BETGAMES_OWNER', bo)
                    resolve()
                }).catch(function (error) {
                    Vue.$log.debug('store deleteBetgame error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        clearDataBeforeLogin ({ commit }) {
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_BETGAMES_OWNER', [])
            commit('SET_BETGAMES_AUTHOR', [])
            commit('SET_BETGAMES_PARTICIPANT', [])
            commit('SET_MISSING_BETS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_USER_BETGAME_POINTS', [])
            commit('SET_POINTS_CORRECTIONS_COUNT', 0)
            commit('SET_LIVETICKER', [])
            commit('SET_LIVE_RESULTS', [])
            commit('SET_TOURNAMENT_ROUNDS', [])
            commit('SET_TOURNAMENT_MATCHES', [])
            commit('SET_TOURNAMENT_GROUPS', [])
            commit('SET_TOURNAMENT_GROUP_TABLESS', [])
            commit('SET_BETGAME_TEAMS', [])
            commit('SET_BETGAME_TEAMS_COMPETITIONS', [])
            commit('SET_BETGAME_RANKINGS', [])
            commit('SET_REVIEW_POPUP_SHOWN', 0)
            commit('SET_SPECIAL_QUESTION_TEAMS', [])
            commit('SET_CHAT_TOKEN', '')
            commit('SET_CHAT_ITEMS', [])
            commit('SET_UNREAD_COUNT',0)
        },
        logout ({ commit }) {
            commit('SET_LEAGUE_ID', 0)
            commit('SET_USER_BETGAMES', [])
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_BETGAMES_OWNER', [])
            commit('SET_BETGAMES_AUTHOR', [])
            commit('SET_BETGAMES_PARTICIPANT', [])
            commit('SET_MISSING_BETS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_USER_BETGAME_POINTS', [])
            commit('SET_POINTS_CORRECTIONS_COUNT', 0)
            commit('SET_LIVETICKER', [])
            commit('SET_LIVE_RESULTS', [])
            commit('SET_TOURNAMENT_ROUNDS', [])
            commit('SET_TOURNAMENT_MATCHES', [])
            commit('SET_TOURNAMENT_GROUPS', [])
            commit('SET_TOURNAMENT_GROUP_TABLESS', [])
            commit('SET_BETGAME_TEAMS', [])
            commit('SET_BETGAME_TEAMS_COMPETITIONS', [])
            commit('SET_BETGAME_RANKINGS', [])
            commit('SET_REVIEW_POPUP_SHOWN', 0)
            commit('SET_SPECIAL_QUESTION_TEAMS', [])
            commit('SET_CHAT_TOKEN', '')
            commit('SET_CHAT_ITEMS', [])
            commit('SET_CHATS_PARTICIPANT', [])
            commit('SET_UNREAD_COUNT',0)
            commit('CLEAR_USER_DATA')
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('spmlr')
            window.localStorage.removeItem('spmvr')
            window.localStorage.removeItem('spmbpc')
            window.localStorage.removeItem('spmhh')
            for(var i=0; i<window.localStorage.length; i++) {
                const key = window.localStorage.key(i)
                if(key.indexOf('match_stats_') >= 0) {
                    window.localStorage.removeItem(key)
                }
            }
    
            commit('SET_BETGAME_ID', 0)
            commit('SET_BETGAME', 0)
            window.localStorage.removeItem('spmbgid')
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                window.localStorage.removeItem('betgameId')
            }
            
            //window.localStorage.removeItem(storageKey)
            //Cookie.set(cookieName, uuidv4(), { expires: 360 })
        },
        switchBetgame ({ commit }, betgame) {
            return new Promise((resolve, reject) => {
                commit('SET_LEAGUE_ID', 0)
                commit('SET_USER_BETGAMES', [])
                commit('SET_USER_NEIGHBOUR_RANKINGS', [])
                commit('SET_USER_RANKING', [])
                commit('SET_USER_LIVE_RANKING', [])
                commit('SET_LIVE_RANKINGS', [])
                commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
                commit('SET_MATCHDAY_WINS', [])
                commit('SET_MATCHDAY_RANKINGS', [])
                commit('SET_USER_MATCHDAY_RANKINGS', [])
                commit('SET_CURRENT_MATCHDAY_BET', 0)
                commit('SET_CURRENT_MATCHDAY_RANKING', 0)
                commit('SET_MATCHDAY_COUNT', 0)
                commit('SET_SCHEDULE', [])
                commit('SET_BETS', [])
                commit('SET_ALL_BETS', [])
                commit('SET_TABLE', [])
                commit('SET_TEAMS', [])
                commit('SET_TODAYS_MATCHES', [])
                commit('SET_RUNNING_MATCHES', [])
                commit('SET_MATCH_BETS', [])
                commit('SET_MATCHDAY_RANKING_SORTING', 1)
                commit('SET_QUESTIONS', [])
                commit('SET_ANSWERS', [])
                commit('SET_SOLUTIONS', [])
                commit('SET_USER_COUNT', -1)
                commit('SET_BET_COUNT', -1)
                commit('SET_BETGAME_USERS', [])
                commit('SET_USER_BETGAME_POINTS', [])
                commit('SET_POINTS_CORRECTIONS_COUNT', 0)
                commit('SET_LIVETICKER', [])
                commit('SET_LIVE_RESULTS', [])
                commit('SET_TOURNAMENT_ROUNDS', [])
                commit('SET_TOURNAMENT_MATCHES', [])
                commit('SET_TOURNAMENT_GROUPS', [])
                commit('SET_TOURNAMENT_GROUP_TABLESS', [])
                commit('SET_BATTLE_BET_SELECTED_TAB', '')
                commit('SET_COMPETITIONS', [])
                commit('SET_BETGAME_TEAMS', [])
                commit('SET_BETGAME_TEAMS_COMPETITIONS', [])
                commit('SET_BETGAME_RANKINGS', [])
                //commit('SET_REVIEW_POPUP_SHOWN', 0)
                commit('SET_SPECIAL_QUESTION_TEAMS', [])
                commit('SET_CHAT_TOKEN', '')
                commit('SET_CHAT_ITEMS', [])
                commit('SET_UNREAD_COUNT',0)
                
                if(betgame === 1) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_FUNKE)
                } else if(betgame === 2) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else if(betgame === 12) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else if(betgame === 31) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DEFAULT)
                }
                
                this.dispatch('setBetgameId', betgame)
                this.dispatch('fetchCurrentSeason', betgame)
                this.dispatch('fetchCompetitions', '')
                const that = this
                this.dispatch('fetchBetgame', betgame).then(function (response1) {
                    //that.dispatch('fetchBetgameLeague', betgame).then(function (response2) {
                        that.dispatch('fetchSchedule', response1).then(function (response3) {
                            resolve(response1)
                        })
                    //})
                })
            })
        },
        addBet ({ commit }, bet) {
            commit('ADD_BET', bet)
        },
        setLastBetSaveDate ({ commit }, date) {
            commit('SET_LAST_BET_SAVE_DATE', date)
        },
        setLastAnswerSaveDate ({ commit }, date) {
            commit('SET_LAST_ANSWER_SAVE_DATE', date)
        },
        updateBet ({ commit }, bet) {
            const missing = this.state.missing_bets
            return new Promise((resolve, reject) => {
                APIService.putBet(this.state.token, bet)
                .then(function (response) {
                    if(response.status >= 200 && response.status <= 204) {
                        commit('UPDATE_BET', bet)
                    }
                    commit('SET_UPDATE_USER_BETGAMES', true)
                    if(missing.length > 0) {
                        var missing_new = []
                        for (var i = 0; i < missing.length; i++) {
                            const m = missing[i]
                            const n = {
                                "id": m.id,
                                "matchcount": m.matchcount,
                                "betcount": m.betcount
                            }
                            if (n.id === bet.fk_betgame && n.betcount < n.matchcount) {
                                n.betcount = n.betcount + 1
                            }
                            missing_new.push(n)
                        }
                        commit('SET_MISSING_BETS', missing_new)
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        updateUserBetgame ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.putUserBetgame(this.state.token, data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        updateAnswer ({ commit }, answer) {
            Vue.$log.debug('updateAnswer1: ' + JSON.stringify(this.state.answers))
            const that = this
            return new Promise((resolve, reject) => {
                var deleteParam = answer
                if(Array.isArray(answer)) {
                    deleteParam = answer[0]
                }
                APIService.deleteUserAnswers(deleteParam)
                .then(function (response) {
                    Vue.$log.debug('updateAnswer2: ' + JSON.stringify(that.state.answers))
                    return new Promise((res, rej) => {
                        APIService.postAnswer(answer)
                        .then(function (response) {
                            Vue.$log.debug('updateAnswer3: ' + JSON.stringify(that.state.answers))
                            commit('SET_LAST_ANSWER_SAVE_DATE', moment().format('YYYY-MM-DD HH:mm:ss'))
                            commit('SET_UPDATE_ANSWER_COUNT', true)
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        updateUserNotifications ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_notifications', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_NOTIFICATIONS', data)
            })
        },
        updateUserNickname ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_nickname', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_NICKNAME', data)
            })
        },
        updateUserEmail ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_email', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_EMAIL', data)
            })
            /*
            APIService.patchUser(this.state.token, this.getters.getUserId, user)
            .then(function (response) {
                commit('UPDATE_USER_EMAIL', user)
            })
            .catch(function (error) {
                // handle error
            })
            .finally(function () {
                // always executed
            })
             */
        },
        updateUserPassword ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_pass', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ data }) => {
            })
            /*
            APIService.patchUser(this.state.token, this.getters.getUserId, user)
            .then(function (response) {
                //this.$store.dispatch('logout')
            })
            .catch(function (error) {
            })
            .finally(function () {
                // always executed
            })
             */
        },
        refreshToken ({ commit }, params) {
            return axios.post(this.state.apiUrl + '/rpc/refresh_token', params, {
                    contentType: "application/json",
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    }
                })
                .then(({ data }) => {
                    if(data && data.length === 1 && data[0].token) {
                        setBGValue('spmltr', new Date())
                        commit('SET_TOKEN', data)
                    } else if(data && data.token && data.token.length > 0) {
                        setBGValue('spmltr', new Date())
                        commit('SET_TOKEN', [data])
                    } else {
                        Vue.$log.debug('refreshToken error: ' + JSON.stringify(data))
                    }
                })
                .catch(function (error) {
                    Vue.$log.debug('refreshToken ' + error)
                })
        },
        patchBetgame ({ commit }, params) {
            APIService.patchBetgame(this.state.betgameId, params)
            .then(function (response) {
                commit('UPDATE_BETGAME', params)
            })
            .catch(function (error) {
                Vue.$log.debug('store patchBetgame catch ' + error)
            })
            .finally(function () {
                Vue.$log.debug('store patchBetgame finally ')
            })
        },
        postQuestion ({ commit }, question) {
            return new Promise((resolve, reject) => {
                APIService.postQuestion(question)
                .then(function (response) {
                    if(response.data.length === 1) {
                        resolve(response.data[0].id)
                    } else {
                        reject()
                    }
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        patchQuestion ({ commit }, question) {
            return new Promise((resolve, reject) => {
                APIService.patchQuestion(question.id, question)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        deleteSolution ({ commit }, questionId) {
            const betgame = this.state.betgameId
            return new Promise((resolve, reject) => {
                APIService.deleteSolutions({ fk_question: questionId })
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        deleteQuestion ({ commit }, questionId) {
            const betgame = this.state.betgameId
            return new Promise((resolve, reject) => {
                APIService.deleteAnswers(questionId)
                .then(function (response) {
                    APIService.deleteSolutions({fk_question: questionId})
                    .then(function (response) {
                        APIService.deleteQuestion(questionId, betgame)
                        .then(function (response) {
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                    .catch(function (error) {
                        reject(error)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        fetchBetCount ({ commit }, preferred) {
            APIService.betCount(this.state.betgameId, preferred)
            .then(function (response) {
                const headers = response.headers
                const crange = headers['content-range']
                const betcount = crange.substring(crange.lastIndexOf('/') + 1)
                commit('SET_BET_COUNT', parseInt(betcount))
            })
            .catch(function (error) {
                Vue.$log.debug('store fetchBetCount error ' + error)
            })
        },
        fetchUserCount ({ commit }, preferred) {
            APIService.userCount(this.state.betgameId, preferred)
            .then(function (response) {
                const headers = response.headers
                const crange = headers['content-range']
                const usercount = crange.substring(crange.lastIndexOf('/') + 1)
                commit('SET_USER_COUNT', parseInt(usercount))
            })
            .catch(function (error) {
                Vue.$log.debug('store fetchUserCount error ' + error)
            })
        },
        startBetgameTransfer ({ commit }, params) {
            Vue.$log.debug('startBetgameTransfer ' + params + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                APIService.startBetgameTransfer(params[0], params[1])
                .then(response => {
                    Vue.$log.debug('startBetgameTransfer ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('startBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameTransfer ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameTransfer(betgameId)
                .then(response => {
                    if(response.data.length === 1) {
                        commit('SET_BETGAME_TRANSFER', response.data[0])
                    }
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameTransfersByUser ({ commit }, userId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameTransfersByUser(userId)
                .then(response => {
                    Vue.$log.debug("fetchBetgameTransfersByUser " + JSON.stringify(response.data))
                    commit('SET_INCOMING_BETGAME_TRANSFERS', response.data)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameTransfersByUser error ' + error)
                    reject()
                })
            });
        },
        deleteBetgameTransfer ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.deleteBetgameTransfer(betgameId)
                .then(response => {
                    commit('SET_BETGAME_TRANSFER', {})
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('deleteBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        finishBetgameTransfer ({ commit }, betgameId) {
            Vue.$log.debug('finishBetgameTransfer ' + betgameId)
            return new Promise((resolve, reject) => {
                APIService.finishBetgameTransfer(betgameId)
                .then(response => {
                    Vue.$log.debug('finishBetgameTransfer ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('finishBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        postUnsubscription ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.postUnsubscription(data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        checkInvitation ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameByInvitation(data[0], data[1])
                .then(function (response) {
                    if(response.data.length > 0) {
                        resolve(response.data)
                    } else {
                        reject()
                    }
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        postB2BContact ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.postB2BContact(data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        patchUser ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.patchUser(this.state.token, this.state.user.id, params)
                .then(function (response) {
                    commit('UPDATE_USER', params)
                    Vue.$log.debug('UPDATE_USER commited ' + JSON.stringify(params))
                    resolve()
                })
                .catch(function (error) {
                    Vue.$log.debug('store patchUser catch ' + error)
                    reject()
                })
            })
        },
        updateSolution ({ commit }, solution) {
            return new Promise((resolve, reject) => {
                var deleteParam = solution
                var isArray = false
                if(Array.isArray(solution)) {
                    deleteParam = solution[0]
                    isArray = true
                }
                const solutions = this.state.solutions.filter(s => s.fk_question !== deleteParam.fk_question)
                APIService.deleteSolutions(deleteParam)
                .then(function (response) {
                    return new Promise((res, rej) => {
                        APIService.postSolution(solution)
                        .then(function (response) {
                            if(isArray) {
                                solutions.push(...solution)
                            } else {
                                solutions.push(solution)
                            }
                            commit('SET_SOLUTIONS', solutions)
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        fetchBetgameUsers ({ commit }, betgameId ) {
            const currentUserId = this.state.user ? this.state.user.id : 0
            var isAdmin = false
            if(currentUserId !== undefined && currentUserId > 0 && this.state.betgame && 
              '' + this.state.betgame.fk_user === '' + currentUserId && '' + this.state.betgame.id === '' + betgameId) {
                isAdmin = true
            }
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameUsersTable(betgameId, isAdmin)
                .then(response => {
                    var temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        const u = response.data[i]
                        temp.push({"fk_user": u.bg_user.id, "email": u.bg_user.email, "nickname": u.bg_user.nickname, "fk_betgame": betgameId, "avatar": u.bg_user.avatar})
                    }
                    temp.sort(function(a,b) {
                        if(a.fk_user === currentUserId) {
                            return -1
                        }
                        if(b.fk_user === currentUserId) {
                            return 1
                        }                        
                        var x = a.nickname.toUpperCase() < b.nickname.toUpperCase() ? -1 : 1;
                        return x;
                    })
                    commit('SET_BETGAME_USERS', temp)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameUsers error ' + error)
                    reject()
                })
            });
        },
        updateBetGoalsHome ({ commit }, params ) {
            commit('UPDATE_BET_GOALS_HOME', params)
        },
        updateBetGoalsGuest ({ commit }, params ) {
            commit('UPDATE_BET_GOALS_GUEST', params)
        },
        updateUserBetgamePoints ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.putUserBetgamePoints(data)
                .then(function (response) {
                    if(response.status >= 200 && response.status <= 204) {
                        commit('UPDATE_USER_BETGAME_POINTS', data)
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        deleteUserBetgamePoints ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.deleteUserBetgamePoints(data)
                .then(function (response) {
                    if(response.status >= 200 && response.status <= 204) {
                        //commit('UPDATE_USER_BETGAME_POINTS', data)
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        fetchUserBetgamePoints ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchUserBetgamePoints(betgameId)
                .then(response => {
                    commit('SET_USER_BETGAME_POINTS', response.data)
                    resolve(response)
                })
                .catch(error => {
                    Vue.$log.debug('fetchUserBetgamePoints error ' + error)
                    reject(error)
                })
            });
        },
        fetchPointsCorrectionsCount ({ commit }, foo) {
            APIService.pointsCorrectionsCount(this.state.betgameId)
            .then(function (response) {
                const headers = response.headers
                const crange = headers['content-range']
                const count = crange.substring(crange.lastIndexOf('/') + 1)
                commit('SET_POINTS_CORRECTIONS_COUNT', parseInt(count))
            })
            .catch(function (error) {
                Vue.$log.debug('store fetchPointsCorrectionsCount error ' + error)
            })
        },
        clearSelectedBetgame ({ commit }, foo) {
            commit('SET_BETGAME_ID', 0)
            commit('SET_BETGAME', 0)
            window.localStorage.removeItem('spmbgid')
            if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
                window.localStorage.removeItem('betgameId')
            }
        },
        patchUserApp ({ commit }, params) {
            APIService.patchUserApp(params.fk_user, params.push_token, params)
            .then(function (response) {
                commit('SET_APP_VERSION', params.app_version)
            })
            .catch(function (error) {
                Vue.$log.debug('patchUserApp catch ' + error)
            })
            .finally(function () {
                Vue.$log.debug('patchUserApp finally ')
            })
        },
        postUserApp ({ commit }, params) {
            APIService.postUserApp(params)
            .then(function (response) {
            })
            .catch(function (error) {
                Vue.$log.debug('postUserApp catch ' + error)
            })
            .finally(function () {
                Vue.$log.debug('postUserApp finally ')
            })
        },
        fetchQuestionAnswers ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.getQuestionAnsers(params.fk_question)
                .then(response => {
                    commit('SET_QUESTION_ANSWERS', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
            });
        },
        fetchBetgameRankings ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameRankings(params.fk_betgame, this.state.betgame.season.id)
                .then(response => {
                    commit('SET_BETGAME_RANKINGS', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
            });
        },
        fetcheLivetickerData ({ commit }, league ) {
            APIService.getLivetickerData(league)
            .then(response => {
                commit('SET_LIVETICKER', response.data)
                /*
                //console.log('############### ' + JSON.stringify(response.data.Results))
                commit('SET_LIVETICKER', response.data.Events)
                for (var i = 0; i < response.data.Results.length; i++) {
                    if(response.data.Results[i].Running === 'true') {
                        commit('UPDATE_SCHEDULE', response.data.Results[i])
                    }
                }
                */
                
                /*
                const t = this.state.matches
    
                var schedule = []
                for (var i = 0; i < this.state.matches.length; i++) {
                    const match = this.state.matches[i]
                    const updateMatch = response.data.Results.filter(r => r.HeimId === '' + match.fk_team_home && r.GastId === '' + match.fk_team_guest)
                    if(updateMatch.length === 1) {
                        var u = updateMatch[0]
                        console.log('################## ' + JSON.stringify(u))
                        Vue.set(u, 'livegoalshome', 1)
                        Vue.set(u, 'livegoalsguest', 2)
                        schedule.push(u)
                    } else {
                        schedule.push(match)
                    }
                }
                commit('SET_SCHEDULE', schedule)
                
                
                var schedule = []
                for (var i = 0; i < t.length; i++) {
                    var u = t[i]
                    //current_minute "0"
                    //finished enum('yes', 'no')
                    //current_period enum('game-start', 'first-half-end', 'second-half-start', 'second-half-end', 'first-extra-start', 'first-extra-end', 
                    //'second-extra-start', 'second-extra-end', 'penalty-start', 'game-end')
                    const v = response.data.Results.filter(r => r.HeimId === '' + u.fk_team_home && r.GastId === '' + u.fk_team_guest)
                    if(v.length === 1) {
                        console.log('################## ' + JSON.stringify(v[0]))
                        Vue.set(u, 'livegoalshome', 1)
                        Vue.set(u, 'livegoalsguest', 2)
                        //u.livegoalshome = 3 //parseInt(v[0].ToreHeim)
                        //u.livegoalsguest = 2 //parseInt(v[0].ToreGast)
                    }
                }
                commit('SET_LIVE_RESULTS', response.data.Results)
                
                const t = [
                    {
                        "ToreHeim": "0",
                        "ToreGast": "2",
                        "HeimId": "9",
                        "GastId": "2"
                    },
                    {
                        "ToreHeim": "1",
                        "ToreGast": "3",
                        "HeimId": "8",
                        "GastId": "5"
                    },
                    {
                        "ToreHeim": "5",
                        "ToreGast": "0",
                        "HeimId": "1",
                        "GastId": "27"
                    },
                    {
                        "ToreHeim": "3",
                        "ToreGast": "1",
                        "HeimId": "24",
                        "GastId": "23"
                    },
                    {
                        "ToreHeim": "2",
                        "ToreGast": "1",
                        "HeimId": "20",
                        "GastId": "25"
                    },
                    {
                        "ToreHeim": "1",
                        "ToreGast": "2",
                        "HeimId": "3",
                        "GastId": "15"
                    }
                ]
                commit('SET_LIVE_RESULTS', t)
                */
            })
            .catch(error => {
                Vue.$log.debug('fetcheLivetickerData error ' + error)
            })
        },
        saveBetgameMatches ({ commit }, data) {
            const missing = this.state.missing_bets
            return new Promise((resolve, reject) => {
                APIService.postBetgameMatches(this.state.token, data.matches)
                .then(function (response) {
                    Vue.$log.debug('saveBetgameMatches ok ' + response.status)
                    if(response.status >= 200 && response.status <= 204) {
                        //commit('UPDATE_BETGAME_MATCHES', bet)
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    Vue.$log.debug('saveBetgameMatches error ' + error)
                    reject(error)
                })
            })
        },
        deleteBetgameMatches ({ commit }, data) {
            const betgame = data.betgame
            const matches = data.matches
            if(betgame !== this.state.betgame.id) {
                Vue.$log.debug('deleteBetgameMatches betgame error ' + betgame + ' --- ' + this.state.betgame.id)
                return 
            }
            return new Promise((resolve, reject) => {
                APIService.deleteBattleMatches(betgame, matches)
                .then(function (response) {
                    Vue.$log.debug('deleteBetgameMatches ok ' + response.status)
                    resolve(response)
                })
                .catch(function (error) {
                    Vue.$log.debug('deleteBetgameMatches error ' + error)
                    reject(error)
                })
                APIService.deleteBets(betgame, matches)
                .then(function (response) {
                    Vue.$log.debug('deleteBetgameMatches ok ' + response.status)
                    resolve(response)
                })
                .catch(function (error) {
                    Vue.$log.debug('deleteBetgameMatches error ' + error)
                    reject(error)
                })
            })
        },
        fetchUnsubscribed ({ commit }, user ) {
            Vue.$log.debug('fetchUnsubscribed ' + JSON.stringify(user))
            return new Promise((resolve, reject) => {
                APIService.fetchUnsubscribed(user.id, user.email)
                .then(response => {
                    Vue.$log.debug('fetchUnsubscribed ' + JSON.stringify(response.data))
                    if(response.data.length > 0) {
                        commit('SET_USER_UNSUBSCRIBED', response.data[0])
                    }
                    resolve(response)
                })
                .catch(error => {
                    Vue.$log.debug('fetchUnsubscribed error ' + error)
                    reject(error)
                })
            });
        },
        fetchCompetitions ({ commit }, unused ) {
            APIService.fetchCompetitions()
            .then(response => {
                commit('SET_COMPETITIONS', response.data)
            })
            .catch(error => {
                Vue.$log.debug('fetcheLivetickerData error ' + error)
            })
        },
        fetchQuestionRankings ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchQuestionRankings(betgameId)
                .then(response => {
                    commit('SET_QUESTION_RANKINGS', response.data)
                    resolve(response)
                })
                .catch(error => {
                    Vue.$log.debug('fetchQuestionRankings error ' + error)
                    reject(error)
                })
            });
        },
        saveBetgameTeams ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.postBetgameTeams(this.state.token, data)
                .then(function (response) {
                    Vue.$log.debug('saveBetgameTeams ok ' + response.status)
                    if(response.status >= 200 && response.status <= 204) {
                        
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    Vue.$log.debug('saveBetgameTeams error ' + error)
                    reject(error)
                })
            })
        },
        fetchBetgameTeams ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameTeams(betgameId)
                .then(response => {
                    Vue.$log.debug('fetchBetgameTeams ' + JSON.stringify(response.data))
                    commit('SET_BETGAME_TEAMS', response.data)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameTeams error ' + error)
                    reject()
                })
            });
        }
    },
    getters: {
        getUserById: state => id => {
            const t = state.betgameUsers.filter(user => user.fk_user === id)
            return t.length > 0 ? t[0] : null
        },
        userBetgamesCount(state) {
            const p = state.betgames_participant || 0
            const a = state.betgames_author || 0
            const o = state.betgames_owner || 0
            return p + a + o
        },
        pointsBetResult(state) {
            return state.betgame.points_bet_result
        },
        pointsBetGoaldiff(state) {
            return state.betgame.points_bet_goaldiff
        },
        pointsBetTendency(state) {
            return state.betgame.points_bet_tendency
        },
        getUserId (state) {
            if(state.user) {
                return state.user.id
            }
            return null
        },
        loggedIn (state) {
            var tokenExpired = false
            if (state.token) {
                try {
                    const payload = jwt_decode(state.token)
                    const expDate = payload.exp * 1000
                    if (expDate < Date.now()) {
                        tokenExpired = true
                    }
                } catch (e) {
                    tokenExpired = false
                }
            }
            return !!state.user && !!state.token && !tokenExpired
        },
        getClosestFutureMatch: state => {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(!timeZone) {
                timeZone = 'Europe/Berlin'
            }
            const now = moment().tz(timeZone)
            var result = null
            var resultDate = null
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                if(match.status && match.status.trim().length > 0) {
                    continue
                }
                //const matchdate = Date.parse(match.matchdate)
                //const matchdate = moment(match.matchdate, MATCH_DATE_FORMAT, true)
                const valueFromDB = moment.tz(String(match.matchdate), "Europe/Berlin")
                const matchdate =  valueFromDB.clone().tz(timeZone)
                if (matchdate > now) {
                    if (result == null || matchdate < resultDate) {
                        result = match
                        resultDate = matchdate
                    }
                }
            }
            return result
        },
        matchWithLiveGoals: state => {
            const now = Date.now()
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)) {
                    return true
                }
            }
            return false
        },
        getLiveMatch: state => {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(!timeZone) {
                timeZone = 'Europe/Berlin'
            }
            const now = moment().tz(timeZone)
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                if(match.status && match.status.trim().length > 0) {
                    continue
                }
                if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)) {
                    return match
                }
                //const matchstart = moment(match.matchdate, MATCH_DATE_FORMAT, true)
                //const matchend = moment(match.matchdate, MATCH_DATE_FORMAT, true).add(120, 'minutes')
                const valueFromDB = moment.tz(String(match.matchdate), "Europe/Berlin")
                const matchstart =  valueFromDB.clone().tz(timeZone)
                const matchend =  valueFromDB.clone().tz(timeZone).add(120, 'minutes')
                if (matchstart < now && matchend > now && !isNumeric(match.goalshome) && !isNumeric(match.goalsguest)) {
                    return match
                }
            }
            return null
        },
        getMatchesByMatchday: state => matchday => {
            if(state.betgame && state.betgame.type && parseInt(state.betgame.type) === TYPE_BATTLE_ID) {
                return state.matches
            } else if(state.betgame && state.betgame.type && parseInt(state.betgame.type) === TYPE_TEAMS_ID) {
                return state.matches
            } else {
                return state.matches.filter(match => match.matchday === matchday)
            }
        },
        getTodaysMatches: state => {
            var result = []
            for (var i = 0; i < state.todaysMatches.length; i++) {
                const id = state.todaysMatches[i]
                const m = state.matches.filter(match => match.id === id)
                if(m && m.length > 0) {
                    result.push(m[0])
                }
            }
            return result
        },
        todaysMatchesSameMatchday: state => {
            var matchday = 0
            for (var i = 0; i < state.todaysMatches.length; i++) {
                const id = state.todaysMatches[i]
                const matches = state.matches.filter(match => match.id === id)
                if(matches && matches.length > 0) {
                    const match = matches[0]
                    if(matchday === 0) {
                        matchday = match.matchday
                    } else if(matchday > 0) {
                        if(matchday !== match.matchday) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        getMatchBet: state => match => {
            if(!state.matchBets || state.matchBets.length === 0) {
                return []
            }
            return state.matchBets.filter(matchBet => matchBet.fk_match === match)
        },
        getMatcheById: state => matchId => {
            return state.matches.filter(match => match.id === matchId)[0]
        },
        getTodaysMatchesMatchdayStarted(state) {
            const tm = state.todaysMatches
            for (var i = 0; i < tm.length; i++) {
                const m = state.matches.filter(match => match.id === (tm[i]))[0]
                const matchdayMatches = state.matches.filter(match => match.matchday === m.matchday)
                const started = matchdayMatches.filter(match => (isNumeric(match.goalshome) && isNumeric(match.goalsguest)) ||
                  (isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)))
                if(started.length === 0) {
                    return false
                } else {
                    return true
                }
            }
            return false
        },
        getMatchdayStarted: state => matchday => {
            const matchdayMatches = state.matches.filter(match => match.matchday === matchday)
            const started = matchdayMatches.filter(match => (isNumeric(match.goalshome) && isNumeric(match.goalsguest)) ||
              (isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)))
            return started.length > 0
        },
        getMatchdayFinished: state => matchday => {
            const matchdayMatches = state.matches.filter(match => match.matchday === matchday)
            const finished = matchdayMatches.filter(match => (isNumeric(match.goalshome) && isNumeric(match.goalsguest)))
            return finished.length === matchdayMatches.length
        },
        getTeamById: state => teamId => {
            return state.teams.filter(team => team.id === teamId)[0]
        },
        getTeamByKickerId: state => kickerId => {
            return state.teams.filter(team => parseInt(team.kickerid) === parseInt(kickerId))[0]
        },
        getMatchdayWin: state => (userId, matchday) => {
            return state.matchDayWins.filter(win => win.fk_user === userId && win.matchday === matchday)
        },
        getMatchdayWins: state => userId => {
            return state.matchDayWins.filter(win => win.fk_user === userId)
        },
        getAllBetByMatchUser: state => (betgame, matchId, userid) => {
            if(state.user.id === userid) {
                const b = state.bets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame && bet.fk_user === userid)
                if (b.length > 0) {
                    return b[0]
                } else {
                    return null
                }
            } else {
                const b = state.allbets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame && bet.fk_user === userid)
                if (b.length > 0) {
                    return b[0]
                } else {
                    return null
                }
            }
        },
        getBetByBetgameAndMatch: state => (betgame, matchId) => {
            const b = state.bets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame)
            if (b.length > 0) {
                return b[0]
            } else {
                return null
                /*
                const b = {
                  "fk_user": parseInt(state.user.id),
                  "fk_match": parseInt(matchId),
                  "fk_betgame": parseInt(2)
                }
                addBet(b)
                //state.bets.push(b)
                return b
                 */
            }
        },
        getMatchStats: state => matchId => {
            const ms = getBGValue('match_stats_' + matchId)
            if (ms !== undefined) {
                const msJSON = JSON.parse(ms)
                if (msJSON != undefined && msJSON != null) {
                    return msJSON
                }
            }
            var home_home_w = 0
            var home_home_d = 0
            var home_home_l = 0
            var home_guest_w = 0
            var home_guest_d = 0
            var home_guest_l = 0
            var guest_home_w = 0
            var guest_home_d = 0
            var guest_home_l = 0
            var guest_guest_w = 0
            var guest_guest_d = 0
            var guest_guest_l = 0
            const cur_match = state.matches.filter(match => match.id === matchId)[0]
            for (var i = 0; i < state.matches.length; i++) {
                var match = state.matches[i]
                if (match['id'] === cur_match.id || match['matchdate'] > cur_match.matchdate) {
                    continue
                }
                var gh = match['goalshome']
                var gg = match['goalsguest']
                if (gh == null || !isNumeric(gh) || gg == null || !isNumeric(gg)) {
                    continue
                }
                if (match['fk_team_home'] === cur_match.fk_team_home) {
                    if (gh > gg) {
                        home_home_w += 1
                    } else if (gh === gg) {
                        home_home_d += 1
                    } else {
                        home_home_l += 1
                    }
                } else if (match['fk_team_guest'] === cur_match.fk_team_home) {
                    if (gg > gh) {
                        home_guest_w += 1
                    } else if (gg === gh) {
                        home_guest_d += 1
                    } else {
                        home_guest_l += 1
                    }
                }
                if (match['fk_team_home'] === cur_match.fk_team_guest) {
                    if (gh > gg) {
                        guest_home_w += 1
                    } else if (gh === gg) {
                        guest_home_d += 1
                    } else {
                        guest_home_l += 1
                    }
                } else if (match['fk_team_guest'] === cur_match.fk_team_guest) {
                    if (gg > gh) {
                        guest_guest_w += 1
                    } else if (gg === gh) {
                        guest_guest_d += 1
                    } else {
                        guest_guest_l += 1
                    }
                }
            }
            
            //state.matches.sort(function (a, b) {
            //  return b.matchdate.localeCompare(a.matchdate);
            //});
            
            var last_matches_home = []
            var last_matches_guest = []
            for (var i = 0; i < state.matches.length; i++) {
                var match = state.matches[i]
                if (match['matchdate'] < cur_match.matchdate && isNumeric(match['goalshome']) && isNumeric(match['goalsguest'])) {
                    if (match['fk_team_home'] == cur_match.fk_team_home || match['fk_team_guest'] == cur_match.fk_team_home) {
                        last_matches_home.push(match)
                    }
                    if (match['fk_team_home'] == cur_match.fk_team_guest || match['fk_team_guest'] == cur_match.fk_team_guest) {
                        last_matches_guest.push(match)
                    }
                }
                
            }
            
            const result = {
                'home': {
                    'home_w': home_home_w,
                    'home_d': home_home_d,
                    'home_l': home_home_l,
                    'guest_w': home_guest_w,
                    'guest_d': home_guest_d,
                    'guest_l': home_guest_l,
                    'last_matches': last_matches_home.slice(0, Math.min(5, last_matches_home.length))
                },
                'guest': {
                    'home_w': guest_home_w,
                    'home_d': guest_home_d,
                    'home_l': guest_home_l,
                    'guest_w': guest_guest_w,
                    'guest_d': guest_guest_d,
                    'guest_l': guest_guest_l,
                    'last_matches': last_matches_guest.slice(0, Math.min(5, last_matches_guest.length))
                }
            }
            
            // localStorage.setItem('match_stats_' + matchId, JSON.stringify(result))
            setBGValue('match_stats_' + matchId, JSON.stringify(result))
            
            return result
        },
        getRankingForDashboard(state) {
            if(state.userNeighbourRankings) {
                const dashboardRankings = [...state.userNeighbourRankings]
                return dashboardRankings
            }
            return []
        },
        getTablePositions: state => match => {
            var result = []
            for (var i = 0; i < state.positions.length; i++) {
                const pos = state.positions[i]
                if(pos.fk_team === match.fk_team_home || pos.fk_team === match.fk_team_guest) {
                    result.push(pos)
                }
            }
            return result
        },
        matchdayFinished: state => matchday => {
            if(state.matches === undefined || state.matches === null || state.matches.length === 0) {
                return true
            }
            const matchdayMatches = state.matches.filter(match => match.matchday === matchday)
            for (var i = 0; i < matchdayMatches.length; i++) {
                const match = matchdayMatches[i]
                if(!isNumeric(match.goalshome) || !isNumeric(match.goalsguest)) {
                    return false
                }
            }
            return true
        },
        getCachingDuration(state) {
            if(state.matches === undefined ||state.matches === null || state.matches.length === 0) {
                return 10
            }
            if(state.runningMatches.length > 0) {
                return 10
            } else if(state.todaysMatches.length > 0) {
                return 10
            } else {
                return 10
            }
        },
        finishedMatches(state) {
            return state.matches.filter(match => isNumeric(match.goalshome) && isNumeric(match.goalsguest)).length
        },
        startedMatches(state) {
            return state.matches.filter(match => (isNumeric(match.goalshome) && isNumeric(match.goalsguest)) ||
              (isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest))).length
        },
        packageVersion() {
            return process.env.PACKAGE_VERSION || '0'
        },
        isUserBetgameParticipant: state => (betgame) => {
            const bg3 = state.betgames_participant.filter(bg => bg.id === parseInt(betgame))
            if(bg3.length === 1) {
                return true
            }
            return false
        },
        isUserConnectedToBetgame: state => (betgame) => {
            const bg1 = state.betgames_owner.filter(bg => bg.id === parseInt(betgame))
            if(bg1.length === 1) {
                return true
            }
            const bg2 = state.betgames_author.filter(bg => bg.id === parseInt(betgame))
            if(bg2.length === 1) {
                return true
            }
            const bg3 = state.betgames_participant.filter(bg => bg.id === parseInt(betgame))
            if(bg3.length === 1) {
                return true
            }
            return false
        },
        getUserAvatar: state => userid => {
            if(state.user && parseInt(state.user.id) === parseInt(userid)) {
                return state.user.avatar
            }
            if(state.betgameUsers === undefined || state.betgameUsers.length === 0) {
                return null
            }
            const u = state.betgameUsers.filter(user => parseInt(user.fk_user) === parseInt(userid))
            if(u !== undefined && u.length === 1) {
                return u[0].avatar
            }
            return null
        },
        todaysMatchesMultipleMatchdays(state) {
            const filtered = state.matches.filter(t => moment(t.matchdate, MATCH_DATE_FORMAT, true).isSame(moment(), 'days'))
            var matchday = 0
            for (var i = 0; i < filtered.length; i++) {
                const m = filtered[i]
                if(matchday > 0 && m.matchday > 0 && matchday !== m.matchday) {
                    return true
                } else if(matchday === 0 && m.matchday > 0) {
                    matchday = m.matchday
                }
            }
            return false
        },
        getCurrentTeams(state) {
            return state.teams.filter(team => !team.is_placeholder && team.sports_type === 1)
        }
    },
    strict: process.env.NODE_ENV !== 'production',
    //plugins: [vuexLocal.plugin, createLogger({})]
    plugins: [vuexLocal.plugin]
})

export const menustore = Vue.observable({
    isNavOpen: false
});

export const menumutations = {
    setIsNavOpen(yesno) {
        menustore.isNavOpen = yesno;
    },
    toggleNav() {
        menustore.isNavOpen = !menustore.isNavOpen;
    }
};
